import {Alert} from "antd";
import * as React from "react";
import {ServerConstraintViolation} from "../service/common/ServerConstraintViolations";

interface IProps {
    constraintViolations: Record<string, Record<string, ServerConstraintViolation>>
}

/**
 * Component that render unhandled server data constraint violations.
 */
class ServerViolations extends React.Component<IProps, any> {
    public render() {
        const {constraintViolations} = this.props;

        return (
            Object.keys(constraintViolations).map((key, index) =>
                Object.keys(constraintViolations[key]).map((typeKey, typeIndex) => {
                    const violation = constraintViolations[key][typeKey];

                    return (!violation.validationDone ? <Alert message={(<span><b>{key}</b>: {violation.message}</span>)} type="error" showIcon={true} key={index + typeIndex} style={{marginBottom: 16}}/> : "")
                })
            )
        )
    }
}

export default ServerViolations;
