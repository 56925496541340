import * as _ from "lodash";
import ApplicationConfig from "../components/common/ApplicationConfig";
import {Language} from "./Language";

export enum UserPermission {
    LOGIN = 'LOGIN',
    RECEIVE_PACKAGE = 'RECEIVE_PACKAGE',
    SEND_PACKAGE = 'SEND_PACKAGE',
    SEND_PACKAGE__PUBLIC = "SEND_PACKAGE__PUBLIC",
    SEND_PACKAGE__INTERNAL = "SEND_PACKAGE__INTERNAL",
    SEND_PACKAGE__PRIVATE = "SEND_PACKAGE__PRIVATE",
    SEND_PACKAGE__BRIEFCASE = "SEND_PACKAGE__BRIEFCASE",
    ALLOW_DOWNLOAD_WITHOUT_LOGIN = "ALLOW_DOWNLOAD_WITHOUT_LOGIN",
    PASSWORD_ENCRYPTION = "PASSWORD_ENCRYPTION",
    MFA_NOT_REQUIRED = "MFA_NOT_REQUIRED",
    APPROVE_SEND_PACKAGE = "APPROVE_SEND_PACKAGE",
    SEND_PACKAGE_WITHOUT_APPROVAL = "SEND_PACKAGE_WITHOUT_APPROVAL",
    SEND_PACKAGE_TO_YOURSELF = "SEND_PACKAGE_TO_YOURSELF",
    ALLOW_DOWNLOAD_SENT_FILES = "ALLOW_DOWNLOAD_SENT_FILES",
    FILE_CHECK_RESULT_DETAILS = "FILE_CHECK_RESULT_DETAILS",
    SET_APPROVER = "SET_APPROVER",
    COOPERATIVE_PACKAGES="COOPERATIVE_PACKAGES",
    SET_PACKAGE_PERSISTENT="SET_PACKAGE_PERSISTENT",
    PERSISTENT_ACCOUNT="PERSISTENT_ACCOUNT"
}

export interface PersonalSettings {
    collectContacts: boolean
}

export enum PersonalSettingsKey {
    COLLECT_CONTACTS = 'COLLECT_CONTACTS'
}

export interface Approver {
    id: string;
    email?: string;
    firstName?: string;
    lastName?: string;
}

export interface UserConfig {
    userCanSetApprover?:boolean;
    cooperativePackagesAllowed?: boolean;
}

export class User {
    constructor(
        public username: string,
        public id?: string,
        public password?: string,
        public email?: string,
        public firstName?: string,
        public lastName?: string,
        public local?: boolean,
        public deleted?: boolean,
        public guid?: string,
        public samAccountName?: string,
        public company?: string,
        public phoneNumber?: string,
        public permissions: UserPermission[] = [],
        public adfsUser?: boolean,
        public adUser?: boolean,
        public personalSettings?: any,
        public language?: Language,
        public twoFactorAuth?: boolean,
        public approvers: Approver[] = [],

        public userConfig?: UserConfig,
    ) {
        //
    }

    public test() {
        return true;
    }

    public hasPermission(permission: UserPermission) {
        return _.includes(this.permissions, permission);
    }

    public hasOneOfPermissions(...permissions: UserPermission[]) {
        for (const permission of permissions) {
            if (this.hasPermission(permission)) {
                return true;
            }
        }

        return false;
    }


    public mfaEnforceRequirement(applicationConfig: ApplicationConfig): boolean {

        // mfa uz ma, nevynucujeme
        if (this.twoFactorAuth) return false;

        // ma pravo nemit mfa, nevynucujeme
        if (this.hasPermission(UserPermission.MFA_NOT_REQUIRED)) return false;

        // resime politiku
        if (this.adfsUser) {
            return applicationConfig?.mfaRequiredForAdfsUsers!;
        }
        if (this.local) {
            return applicationConfig?.mfaRequiredForLocalUsers!;
        }
        if (this.adUser) {
            return applicationConfig?.mfaRequiredForAdUsers!;
        }
        return false;
    }

    public static fromPOJO(data: any) {
        const user = new User(data.username);

        user.id = data.id;
        user.username = data.username;
        user.password = data.password;
        user.email = data.email;
        user.firstName = data.firstName;
        user.lastName = data.lastName;
        user.local = data.local;
        user.company = data.company;
        user.phoneNumber = data.phoneNumber;
        user.permissions = data.permissions;
        user.adfsUser = data.adfsUser;
        user.adUser = data.adUser;
        user.language = data.language;
        user.twoFactorAuth = data.twoFactorAuth;
        user.approvers = data.approvers;
        user.userConfig = data.userConfig;

        return user;
    }

    public setTwoFactorAuth(value: boolean) {
        this.twoFactorAuth = value;
    }
}
