import {message} from 'antd';
import Axios from "axios";
import * as NProgress from "nprogress";
import 'nprogress/nprogress.css';
import * as React from "react";
import {AppContext} from "../AppContext";
import {SofieConfig} from "../domain/common/SofieConfig";
import {AuthService} from "../service/AuthService";
import {intl} from "../sal-ui/Intl";

declare let GlobalSofieConfig: SofieConfig;

class AxiosService {

    public static setUpInterceptors(appContext: AppContext, authService: AuthService) {
        Axios.defaults.baseURL = GlobalSofieConfig.webApiUrl;
        Axios.defaults.withCredentials = true;
        Axios.defaults.xsrfCookieName = 'User-XSRF-TOKEN';
        Axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

        NProgress.configure({showSpinner: false});

        Axios.interceptors.request.use(config => {
            config.headers['Accept-Language'] = appContext.language;

            if (!(config.params && config.params.withoutProgressBar)) {
                NProgress.start();
            }

            return config
        });

        Axios.interceptors.response.use(
            response => {
                if (!(response.config.params && response.config.params.withoutProgressBar)) {
                    NProgress.done();
                }

                if (response.headers['x-sofie-jwt-token-lifetime']) {
                    authService.updateJwtTokenExpiration(parseInt(response.headers['x-sofie-jwt-token-lifetime'], 10));
                }

                return response
            },
            error => {
                if (!(error.response?.config?.params && error.response?.config?.params?.withoutProgressBar)) {
                    NProgress.done();
                }

                if (error.response) {
                    this.handleErrorResponse(authService, error.response);
                }

                return Promise.reject(error);
            });
    }

    private static handleErrorResponse(authService: AuthService, errorResponse: any) {
        const ignoreInterceptorHeader = errorResponse.headers['x-sofie-ignore-response-interceptor'];

        if (ignoreInterceptorHeader && parseInt(ignoreInterceptorHeader, 10) === errorResponse.status) {
            // handled in components

            return;
        }

        if (errorResponse.config.url === "/api/public/static-resource/config") {
            // skip config loading errors
            console.error("Server error: " + errorResponse.data);

            return;
        }

        switch (errorResponse.status) {
            case 200: // handled in components
            case 201: // handled in components
            case 202: // handled in components
            case 400: // handled in components
            case 402:
                break;
            case 401:
                authService.logout();
                break;
            case 403:
                message.error((
                    <React.Fragment>
                        <b>{intl.formatMessage({id: "error.server-forbidden"})}</b>: {JSON.stringify(errorResponse.data)}
                    </React.Fragment>
                ), 5);

                break;
            case 404: // handled in components
                break;
            case 429:
                message.error((
                    <React.Fragment>
                        <b>{intl.formatMessage({id: "error.too-frequent-action"})}</b>: {JSON.stringify(errorResponse.data)}
                    </React.Fragment>
                ), 60);

                break;
            case 500:
                message.error((
                    <React.Fragment>
                        <b>{(intl) ? intl.formatMessage({id: "error.server-error"}) : "Server error"}</b>: {JSON.stringify(errorResponse.data)}
                    </React.Fragment>
                ), 5);

                break;
            case 520:
                const messageId = (errorResponse.headers['x-sofie-response-reason'] === 'demo') ? 'error.license_demo' : 'error.license_invalid';

                message.error((
                    <React.Fragment>
                        <b>{(intl) ? intl.formatMessage({id: messageId}) : "Server error"}</b>
                    </React.Fragment>
                ), 5);

                break;
            default:
                message.error((
                    <React.Fragment>
                        <b>{(intl) ? intl.formatMessage({id: "error.unexpected-response"}) : "Unexpected server response"}</b>
                    </React.Fragment>
                ), 5);

                break;
        }
    }
}

export default AxiosService;
