import ArrayFilterParam from "./ArrayFilterParam";
import FilterParam from "./FilterParam";
import InFilterParam from "./InFilterParam";
import SimpleOperatorFilterParam from "./SimpleOperatorFilterParam";

class FilterParamsBuilder {

    private params: Record<string, FilterParam> = {};

    public custom(fieldName: string, param?: string) {
        this.params[fieldName] = new SimpleOperatorFilterParam("custom", (param) ? param : fieldName);
        return this;
    }

    public eq(fieldName: string, param?: string) {
        this.params[fieldName] = new SimpleOperatorFilterParam("eq", (param) ? param : fieldName);
        return this;
    }

    public ge(fieldName: string, param?: string, stringToModelConverter?: (value: string) => any) {
        this.params[fieldName] = new SimpleOperatorFilterParam("ge", (param) ? param : fieldName, stringToModelConverter);
        return this;
    }

    public le(fieldName: string, param?: string, stringToModelConverter?: (value: string) => any) {
        this.params[fieldName] = new SimpleOperatorFilterParam("le", (param) ? param : fieldName, stringToModelConverter);
        return this;
    }

    public in(fieldName: string, param?: string) {
        this.params[fieldName] = new InFilterParam((param) ? param : fieldName);
        return this;
    }

    public arrayContains(fieldName: string) {
        this.params[fieldName] = new ArrayFilterParam(fieldName, "array_contains");
        return this;
    }

    public build(): Record<string, FilterParam> {
        return this.params;
    }
}

export default FilterParamsBuilder;
