import {ConfigService} from "./ConfigService";
import {AppContext} from "../AppContext";

declare global {
    interface Window {
        captchaOnLoad: () => void;
        grecaptcha: ReCaptchaInstance;
        sitekey: string
    }
}

interface ReCaptchaInstance {
    ready: (cb: () => any) => any;
    execute: (options: ReCaptchaExecuteOptions) => Promise<any>;
    render: (id: string, options: ReCaptchaRenderOptions) => any
}

interface ReCaptchaExecuteOptions {
    action: string
}

interface ReCaptchaRenderOptions {
    sitekey: string;
    size: 'invisible'
}

export class CaptchaService {

    private script?: HTMLScriptElement;

    private widget?: HTMLDivElement;

    constructor(private appContext: AppContext, private configService: ConfigService) {

    }

    public loadCaptcha() {
        window.captchaOnLoad = this.captchaOnLoad;

        this.configService.getCaptcha().then(value => {
            if (value.value.length > 0) {
                const script = document.createElement('script');
                const widget = document.createElement('div');

                widget.id = 'g-recaptcha';

                this.widget = document.body.appendChild(widget);

                window.sitekey = value.value;

                script.type = 'text/javascript';
                script.src = 'https://www.google.com/recaptcha/api.js?onload=captchaOnLoad&render=explicit';
                script.nonce = this.appContext.cspConfig?.nonce;

                this.script = document.body.appendChild(script);
            }
        });
    }

    private captchaOnLoad() {
        window.grecaptcha.render('g-recaptcha', {
            sitekey: window.sitekey,
            size: 'invisible'
        });

        window.grecaptcha.ready(() => {
            return
        });
    }

    public unloadCaptcha() {
        try {
            if (this.widget !== undefined) {
                this.widget.remove();
            }

            if (this.script !== undefined) {
                this.script.remove();
            }
        } catch (e: any) {
            console.error(e.message);
        }
    }

    public checkCaptcha = (value: string): Promise<string> => {
        if (window.grecaptcha != null) {
            return Promise.resolve(window.grecaptcha.execute({action: value}));
        } else {
            return Promise.reject("-");
        }
    };

}
