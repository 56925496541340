import FilterParam from "./FilterParam";

class InFilterParam implements FilterParam {

    constructor(public name: string) {
        this.name = name;
    }

    public build(value: string[]) {
        return (value && value.length > 0) ? `${this.name} in (${value.map(v => `'${v}'`).join(', ')})` : "";
    }
}

export default InFilterParam;
