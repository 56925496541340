import {Form, Input, InputRef, message} from "antd";
import {useForm} from "antd/es/form/Form";
import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import {ContactServiceContext} from "../Contexts";
import {Contact} from "../domain/Contact";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {FormModal, FormModalProps} from "../sal-ui/FormModal";
import {ServerConstraintViolationsHolder} from "../sal-ui/ServerConstraintViolations";
import ValidationUtils from "../service/common/ValidationUtils";


interface IProps extends FormModalProps {
    contact?: Contact
}

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function ContactModal(props: IProps) {

    const contactService = useContext(ContactServiceContext);
    const [form] = useForm();
    const intlMessage = useIntlMessage('contact');

    const emailRef = useRef<InputRef>(null);


    const {visible, title, contact} = props;

    const allowedDomainsRegExp = "(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])";
    const emailRegExpString = "(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")";

    const formItemLayout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    useEffect(() => {

        if (!props.contact) {
            form.resetFields();
        } else {
            form.setFieldsValue(props.contact);
        }

        setTimeout(() => {
            emailRef.current?.focus();
        }, 100);

    }, [props.contact])

    return (
        <FormModal
            visible={visible}
            title={title}
            editMode={props.editMode}
            okText={intlMessage("common.save")}
            cancelText={intlMessage("common.cancel")}
            onCancel={props.onCancel}
            onOk={props.onOk}
            addItem={addItem}
            updateItem={updateItem}
            form={form}
            violationsHolder={serverViolationsHolder}
        >


            <Form form={form} layout={"vertical"}>
                <Form.Item
                    label={intlMessage("common.email")}
                    {...formItemLayout}
                    name={"email"}
                    initialValue={(contact) ? contact.email : undefined}
                    rules={[
                        {required: true, message: intlMessage("required.email")},
                        {validator: ValidationUtils.createServerValidator(serverViolationsHolder, 'UNIQUE'), message: intlMessage("validation.contact-not-unique")},
                        {
                            pattern: new RegExp("^(" + emailRegExpString + "@(" + allowedDomainsRegExp + "))+$"),
                            message: intlMessage("validation.recipients-bad-format")
                        }
                    ]}>
                    <Input ref={emailRef} maxLength={100}/>
                </Form.Item>

                <Form.Item
                    label={intlMessage("common.name")}
                    {...formItemLayout}
                    name={"name"}

                    initialValue={(contact) ? contact.name : undefined}
                    rules={[]}>
                    <Input maxLength={100}/>
                </Form.Item>
            </Form>
        </FormModal>
    );


    function addItem(values: any): Promise<any> {
        return contactService!.add({email: values.email, name: values.name})
            .then(
                () => {
                    message.success(intlMessage('contact-add.added', {email: values.email}));

                    props.onOk!();
                },
                (reason: any) => handleError(values, reason)
            );
    }

    function updateItem(values: any): Promise<any> {
        return contactService!.update({id: props.contact!.id, email: values.email, name: values.name})
            .then(
                () => {
                    message.success(intlMessage('contact-edit.updated', {email: values.email}));

                    props.onOk!();
                },
                (reason: any) => handleError(values, reason)
            );
    }

    function handleError(values: any, error: any) {
        if (error.response.data.constraintViolations.contact_uniq) {
            form.setFields([{
                name: "email",
                value: values.email,
                errors: [new Error(intlMessage("validation.contact-not-unique")).message],
            }
            ]);
        } else {
            return error;
        }
    }
}

export default ContactModal;