import Axios from "axios";
import {User} from "../domain/User";
import PagedResult from "./common/PagedResult";
import QueryOptions from "./common/QueryOptions";

export class UserService {

    private BASE_URL = "/user/user/";

    public add(user: User): Promise<any> {
        return Axios.post(this.BASE_URL, user);
    }

    public update(user: User): Promise<any> {
        return Axios.put(this.BASE_URL, user);
    }

    public patch(user: any): Promise<any> {
        return Axios.patch(this.BASE_URL, user);
    }

    public delete(user: User): Promise<any> {
        return Axios.delete(this.BASE_URL + user.id);
    }

    public getLoggedUser(): Promise<User> {
        return Axios.get(`${this.BASE_URL}`).then((value: any) => {
            return User.fromPOJO(value.data);
        })
    }

    public setPersonalSetting(key: string, value: any): Promise<any> {
        const tmp: any = {};
        tmp[key] = value;

        return Axios.post(this.BASE_URL + "settings", tmp);
    }

    public getPersonalSetting(): Promise<any> {
        return Axios.get(this.BASE_URL + "settings").then((value: any) => {
            return value.data;
        });
    }

    public getList(queryOptions: QueryOptions): Promise<PagedResult<User[]>> {
        return Axios.get(`${this.BASE_URL}?${queryOptions.toQueryParams()}`).then(value => {
            return new PagedResult(value.data.total, value.data.data);
        })
    }

    public resetPassword(username: string): Promise<any> {
        return Axios.post(`/user/reset-password?username=${username}`);
    }

    public validateResetPasswordToken(hash: string): Promise<any> {
        return Axios.head(`/user/reset-password/${hash}`);
    }

    public resetNewPassword(hash: string, password: string): Promise<User> {
        return Axios.put(`/user/reset-password/${hash}`, {password})
            .then(value => {
                return value.data;
            })
    }

    public getAllApprovers() {
        return Axios.get(`${this.BASE_URL}approvers`).then(value => {
            return value.data.data;
        })
    }

    public setApprovers(approvers: string[]) {
        return Axios.post(`${this.BASE_URL}approvers`, {approvers});
    }
}
