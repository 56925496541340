import Axios, {AxiosPromise} from "axios";
import {MultiFactorKey} from "../domain/MultiFactorKey";
import {fromByteArray, toByteArray} from "../utils/base64url";
import PagedResult from "./common/PagedResult";
import QueryOptions from "./common/QueryOptions";

export class MultiFactorKeyService {

    private BASE_URL = "/user/multi-factor-key/";
    private FIDO_BASE_URL = "/user/fido-multi-factor-auth/";
    private TOTP_BASE_URL = "/user/totp-multi-factor-authentication/";

    public startFidoRegistration(currentPassword: string): Promise<any> {
        return Axios.post(`${this.FIDO_BASE_URL}start-registration`, {currentPassword})
            .then(value => {
                const creationOptions = value.data;

                if (creationOptions.excludeCredentials) {
                    creationOptions.excludeCredentials.forEach((credential: any) => {
                        credential.id = toByteArray(credential.id)
                    });
                }

                if (creationOptions.challenge) {
                    creationOptions.challenge = toByteArray(creationOptions.challenge);
                }

                if (creationOptions.user && creationOptions.user.id) {
                    creationOptions.user.id = toByteArray(creationOptions.user.id);
                }

                return creationOptions;
            })
    }

    public finishFidoRegistration(name: string, credential: PublicKeyCredential, currentPassword: string): AxiosPromise {
        const response = credential.response as AuthenticatorAttestationResponse;

        const attestationResponse = {
            id: credential.id,
            response: {
                attestationObject: fromByteArray(response.attestationObject),
                clientDataJSON: fromByteArray(response.clientDataJSON)
            },
            type: "public-key",
            clientExtensionResults: credential.getClientExtensionResults()
        };

        return Axios.post(`${this.FIDO_BASE_URL}finish-registration`, {
            name,
            attestationResponse,
            currentPassword
        });
    }

    public startFidoAuthentication(): Promise<any> {
        return Axios.post(`${this.FIDO_BASE_URL}authentication`, {})
            .then(value => {
                const requestOptions = value.data;

                if (requestOptions.allowCredentials) {
                    requestOptions.allowCredentials.forEach((credential: any) => {
                        credential.id = toByteArray(credential.id)
                    });
                }

                if (requestOptions.challenge) {
                    requestOptions.challenge = toByteArray(requestOptions.challenge);
                }

                if (requestOptions.user && requestOptions.user.id) {
                    requestOptions.user.id = toByteArray(requestOptions.user.id);
                }

                return requestOptions;
            })
    }

    public createTotp(name: string): Promise<any> {
        return Axios.post(`${this.TOTP_BASE_URL}`, {name})
            .then(value => value.data)
    }

    public activateTotp(multiFactorKey: MultiFactorKey, otpCode: string, currentPassword: string): Promise<any> {
        return Axios.post(`${this.TOTP_BASE_URL}${multiFactorKey.id}/activate`, {otpCode, currentPassword})
            .then(value => value.data)
    }

    public cancelActivationTotp(multiFactorKeyId: string): Promise<any> {
        return Axios.post(`${this.TOTP_BASE_URL}${multiFactorKeyId}/cancel`)
            .then(value => value.data)
    }

    public add(multiFactorKey: MultiFactorKey): AxiosPromise {
        return Axios.post(this.BASE_URL, multiFactorKey);
    }

    public update(multiFactorKey: MultiFactorKey): AxiosPromise {
        return Axios.put(this.BASE_URL + multiFactorKey.id, multiFactorKey);
    }

    public delete(multiFactorKey: MultiFactorKey, currentPassword: string): AxiosPromise {
        return Axios.delete(this.BASE_URL + multiFactorKey.id, {data: {currentPassword}});
    }

    public getList(queryOptions: QueryOptions): Promise<PagedResult<MultiFactorKey[]>> {
        return Axios.get(`${this.BASE_URL}?${queryOptions.toQueryParams()}`).then(value => {
            return new PagedResult(value.data.total, value.data.data);
        })
    }

}
