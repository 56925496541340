const IconSendPackage = (props: any) => (
    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M16 464l480-208L16 48v160l320 48-320 48z"></path>
    </svg>
);

const IconUser = (props: any) => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
        <g transform="translate(-4 -4)" fill="none" fillRule="evenodd">
            <path
                d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
                id="user_svg__a"
                fill="#FFF"
            />
        </g>
    </svg>
);

const IconBuild = (props: any) => (
    <svg width="1em" height="1em" viewBox="0 0 22 22" {...props}>
        <defs>
            <path
                d="M22.7 19l-9.1-9.1c.9-2.3.4-5-1.5-6.9-2-2-5-2.4-7.4-1.3L9 6 6 9 1.6 4.7C.4 7.1.9 10.1 2.9 12.1c1.9 1.9 4.6 2.4 6.9 1.5l9.1 9.1c.4.4 1 .4 1.4 0l2.3-2.3c.5-.4.5-1.1.1-1.4z"
                id="build_svg__a"
                style={{fill: "#fff"}}
            />
        </defs>
        <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
            <mask id="build_svg__b" fill="#fff">
                <use xlinkHref="#build_svg__a"/>
            </mask>
            <g mask="url(#build_svg__b)" fill="#FFF">
                <path d="M0 0h24v24H0z"/>
            </g>
        </g>
    </svg>
);

const IconTrash = (props: any) => (
    <svg width="1em" height="1em" viewBox="0 0 14 18" {...props}>
        <defs>
            <path
                d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                id="delete_svg__a"
                style={{fill: "#fff"}}
            />
        </defs>
        <g transform="translate(-5 -3)" fill="none" fillRule="evenodd">
            <mask id="delete_svg__b" fill="#fff">
                <use xlinkHref="#delete_svg__a"/>
            </mask>
            <g mask="url(#delete_svg__b)" fill="#fff">
                <path d="M0 0h24v24H0z"/>
            </g>
        </g>
    </svg>
);

const IconDownload = (props: any) => (
    <svg width="1em" height="1em" viewBox="0 0 14 17" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M14 6h-4V0H4v6H0l7 7 7-7zM0 15v2h14v-2H0z"
                fill="#F7F9FC"
                fillRule="nonzero"
            />
        </g>
    </svg>
);

const IconUpload = (props: any) => (
    <svg width="1em" height="1em" viewBox="0 0 14 17" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M4 13h6V7h4L7 0 0 7h4v6zm-4 2h14v2H0v-2z"
                fill="#F7F9FC"
                fillRule="nonzero"
            />
        </g>
    </svg>
);


const IconExit = (props: any) => (
    <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
        <g transform="translate(-3 -3)" fill="none" fillRule="evenodd">
            <path
                d="M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5a2 2 0 0 0-2 2v4h2V5h14v14H5v-4H3v4a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
                id="exit_to_app_svg__a"
                fill="#434656"
            />
        </g>
    </svg>
);


const IconCloudUpload = (props: any) => (
    <svg width="1em" height="1em" viewBox="0 0 80 53" {...props}>
        <defs>
            <path
                d="M64.5 20.008C62.233 8.579 52.133 0 40 0c-9.633 0-18 5.433-22.167 13.383C7.8 14.443 0 22.889 0 33.124 0 44.089 8.967 53 20 53h43.333C72.533 53 80 45.58 80 36.437c0-8.744-6.833-15.833-15.5-16.43zm-17.833 9.805v13.25H33.333v-13.25h-10L40 13.25l16.667 16.563h-10z"
                id="ic_cloud_upload_24px_svg__a"
            />
        </defs>
        <g fill="none" fillRule="evenodd" opacity={0.18}>
            <mask id="ic_cloud_upload_24px_svg__b" fill="#fff">
                <use xlinkHref="#ic_cloud_upload_24px_svg__a"/>
            </mask>
            <g mask="url(#ic_cloud_upload_24px_svg__b)" fill="#0583FF">
                <path d="M0-13.25h80v79.5H0z"/>
            </g>
        </g>
    </svg>
);

const IconLock = (props: any) => (
    <svg width="1em" height="1em" viewBox="0 0 16 21" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M14 7h-1V5c0-2.76-2.24-5-5-5S3 2.24 3 5v2H2C.9 7 0 7.9 0 9v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H4.9V5c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"
                fill="#A7AABD"
                fillRule="nonzero"
            />
        </g>
    </svg>

);

const GoogleIcon = (props: any) => (
    <svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"/>
        <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"/>
        <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"/>
        <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"/>
        <path fill="none" d="M0 0h48v48H0z"/>
    </svg>

);

const MicrosoftIcon = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21">
        <path fill="#f25022" d="M1 1h9v9H1z"/>
        <path fill="#00a4ef" d="M1 11h9v9H1z"/>
        <path fill="#7fba00" d="M11 1h9v9h-9z"/>
        <path fill="#ffb900" d="M11 11h9v9h-9z"/>
    </svg>

);

const AppleIcon = (props: any) => (
    <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.498 9.667a3.86 3.86 0 0 1 1.838-3.238 3.951 3.951 0 0 0-3.113-1.683c-1.31-.138-2.58.784-3.247.784-.68 0-1.708-.77-2.814-.748A4.146 4.146 0 0 0 2.673 6.91c-1.508 2.611-.383 6.45 1.062 8.56.723 1.034 1.567 2.189 2.673 2.148 1.082-.045 1.486-.69 2.792-.69 1.293 0 1.672.69 2.8.664 1.161-.02 1.893-1.039 2.59-2.082a8.55 8.55 0 0 0 1.184-2.412 3.73 3.73 0 0 1-2.276-3.431ZM11.367 3.358a3.8 3.8 0 0 0 .87-2.723A3.866 3.866 0 0 0 9.734 1.93a3.616 3.616 0 0 0-.892 2.621c.98.01 1.91-.43 2.524-1.193Z"
            fill="#000"/>
    </svg>

);

const OpenIdIcon = (props: any) => (
    <svg height="21px" version="1.1" viewBox="0 0 512 512" width="21px">
        <g>
            <g>
                <path d="M234.849,419v6.623c-79.268-9.958-139.334-53.393-139.334-105.757 c0-39.313,33.873-73.595,84.485-92.511L178.023,180C88.892,202.497,26.001,256.607,26.001,319.866 c0,76.288,90.871,139.128,208.95,149.705l0.018-0.009V419H234.849z" fill="#B2B2B2"/>
                <polygon
                    points="304.772,436.713 304.67,436.713 304.67,221.667 304.67,213.667 304.67,42.429  234.849,78.25 234.849,221.667 234.969,221.667 234.969,469.563" fill="#F7931E"/>
                <path
                    d="M485.999,291.938l-9.446-100.114l-35.938,20.331C415.087,196.649,382.5,177.5,340,177.261 l0.002,36.406v7.498c3.502,0.968,6.923,2.024,10.301,3.125c14.145,4.611,27.176,10.352,38.666,17.128l-37.786,21.254 L485.999,291.938z" fill="#B2B2B2"/>
            </g>
        </g>
        <g id="Layer_1"/>
    </svg>
)

export {IconSendPackage, IconUser, IconBuild, IconTrash, IconDownload, IconUpload, IconExit, IconCloudUpload, IconLock, GoogleIcon, MicrosoftIcon, AppleIcon, OpenIdIcon}

