import * as React from 'react';
import {PaperClipOutlined} from "@ant-design/icons";

interface IProps {
    name: any,
    info?: any,
    value: any,
    key: any,
}

function UploadProgress(props: IProps) {

        const percent = props.value ? props.value : 0;

        return (
            <React.Fragment>
                <div className="upload-progress-item">
                    <div className="upload-progress-item-bar">
                        <div className="upload-progress-item-info">
                            <PaperClipOutlined style={{position: "absolute", lineHeight: "48px", display: "inline-block"}}/>
                            <span className={"upload-progress-item-text"}>
                                <span>{props.name}</span>
                                {props.info && <span className={"float-right"}>{props.info}</span>}
                            </span>
                        </div>
                        <div className="upload-progress-item-progress-overlay" style={{color: "red", width: percent + "%"}}/>
                    </div>
                    <div className={"upload-progress-item-progress"}>{percent}<span>%</span></div>
                </div>

            </React.Fragment>
        );
}

export default UploadProgress;