import Axios from "axios";
import {Config} from "../domain/Config";

export class ConfigService {

    private BASE_URL_CAPTCHA = "/public/captcha";

    public getCaptcha(): Promise<Config> {
        return Axios.get(`${this.BASE_URL_CAPTCHA}`).then(value => {
            return value.data;
        })
    }

}
