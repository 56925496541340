import {Form, Input, InputRef, message, Modal, Transfer} from "antd";
import {useForm} from "antd/es/form/Form";
import {TransferItem} from "antd/lib/transfer";
import {useContext, useEffect, useRef, useState} from "react";
import * as React from "react";
import {injectIntl} from 'react-intl';
import {ContactGroupServiceContext, ContactServiceContext} from "../Contexts";
import {Contact} from "../domain/Contact";
import {ContactGroup} from "../domain/ContactGroup";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {FormModal, FormModalProps} from "../sal-ui/FormModal";
import {ServerConstraintViolationsHolder} from "../sal-ui/ServerConstraintViolations";

interface IProps extends FormModalProps {
    contactGroup?: ContactGroup
}

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function ContactGroupModal(props: IProps) {

    const contactService = useContext(ContactServiceContext);
    const contactGroupService = useContext(ContactGroupServiceContext);
    const [form] = useForm();
    const intlMessage = useIntlMessage('contact');
    const nameRef = useRef<InputRef>(null);

    const [allContacts, setAllContacts] = useState<TransferItem[]>();
    const [usedContactKeys, setUsedContactKeys] = useState<string[]>();
    const [loadingContacts, setLoadingContacts] = useState<boolean>();


    const {visible, title, contactGroup} = props;

    useEffect(() => {

        setLoadingContacts(true);
        setAllContacts(undefined);

        if (!props.contactGroup) {
            form.resetFields();
        } else {
            form.setFieldsValue(props.contactGroup);
        }

        setTimeout(() => {
            nameRef.current?.focus();
            reloadContacts();
        }, 100);

    }, [props.contactGroup]);

    const formItemLayout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    return (
        <FormModal
            visible={visible}
            title={title}
            editMode={props.editMode}
            okText={intlMessage("common.save")}
            cancelText={intlMessage("common.cancel")}
            onCancel={props.onCancel}
            onOk={props.onOk}
            addItem={addItem}
            updateItem={updateItem}
            form={form}
            violationsHolder={serverViolationsHolder}
            width={900}
            // okButtonProps={{disabled: state.loadingContacts}}
        >

            <Form form={form} layout={"vertical"}>
                <Form.Item label={intlMessage("common.name")} {...formItemLayout}
                           name={"name"}
                           initialValue={(contactGroup) ? contactGroup.name : undefined}
                           rules={[
                               {required: true, message: intlMessage("required.name")}
                           ]}>
                    <Input ref={nameRef} maxLength={100}/>
                </Form.Item>

                <Form.Item label={intlMessage("contact-group-edit.contacts")}
                           {...formItemLayout}>
                    <Transfer
                        dataSource={allContacts}
                        showSearch={true}
                        listStyle={{
                            width: 375,
                            height: 300
                        }}
                        titles={[intlMessage("contact-group-edit.all-contacts"), intlMessage("contact-group-edit.contacts-in-group")]}
                        targetKeys={usedContactKeys}
                        onChange={handleContactsChange}
                        render={item => `${item.title}`}
                    />
                </Form.Item>

            </Form>
        </FormModal>
    );

    function addItem(values: any): Promise<any> {
        return contactGroupService!.add({name: values.name}, usedContactKeys)
            .then(
                () => {
                    message.success(intlMessage('contact-group-add.added', {name: values.name}));

                    props.onOk!();
                },
                (reason: any) => handleError(values, reason)
            );
    }

    function updateItem(values: any): Promise<any> {
        return contactGroupService!.update({id: props.contactGroup!.id, name: values.name}, usedContactKeys)
            .then(
                () => {
                    message.success(intlMessage('contact-group-edit.updated', {name: values.name}));

                    props.onOk!();
                },
                (reason: any) => handleError(values, reason)
            );
    }

    function reloadContacts() {
        const contactsInGroup = props.contactGroup?.contacts;

        const usedContactKeys: string[] = [];
        const allContacts: TransferItem[] = [];

        contactService?.getSimpleList().then((contacts: Contact[]) => {
            contacts.forEach(contact => {

                const usedContacts = contactsInGroup?.filter((tmp: Contact) => tmp.id === contact.id);

                const item: TransferItem = {key: contact.id as string, title: contact.email, description: contact.name, chosen: (usedContacts && usedContacts.length > 0)};
                allContacts.push({...item});
                if (item.chosen) {
                    usedContactKeys.push(item.key!);
                }

            });

            setAllContacts(allContacts);
            setUsedContactKeys(usedContactKeys);
            setLoadingContacts(true);

        });

    }

    function handleError(values: any, error: any) {
        if (error.response.data.constraintViolations.contact_group_uniq) {
            form.setFields([{
                name: "name",
                value: values.name,
                errors: [new Error(intlMessage("validation.contact-group-not-unique")).message],
            }]);
        } else {
            return error;
        }
    }

    function handleContactsChange(usedContactKeys: any) {
        setUsedContactKeys(usedContactKeys);
    }
}

export default ContactGroupModal;