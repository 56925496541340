class DataUtils {

    public static convertObjectToKeyValueArray(object: any) {
        const array: object[] = [];

        Object.keys(object).forEach((key: any) => {
            array.push({
                key,
                value: Array.isArray(object[key]) ? object[key].join(", ") : object[key]
            });
        });

        return array;
    }

    public static isEmpty(obj: object) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }

}

export default DataUtils;
