import {Form, Input, InputRef, message, Modal, Select} from "antd";
import {useForm} from "antd/es/form/Form";
import {useContext, useRef} from "react";
import {appContext, applicationConfig, ContactServiceContext, UserServiceContext} from "../Contexts";
import {User} from "../domain/User";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {FormModal, FormModalProps} from "../sal-ui/FormModal";
import {ServerConstraintViolationsHolder} from "../sal-ui/ServerConstraintViolations";
import ValidationUtils from "../service/common/ValidationUtils";

interface IProps extends FormModalProps {
    user?: User
}

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function ProfileModal(props: IProps) {

    const userService = useContext(UserServiceContext);
    const [form] = useForm();
    const intlMessage = useIntlMessage('contact');

    const emailRef = useRef<InputRef>(null);


    const {visible, title, user} = props;

    const formItemLayout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    function updateItem(values: any): Promise<any> {
        return userService!.update(values)
            .then(() => {
                message.success(intlMessage('user-edit-profile.updated', {username: values.username}));

                props.onOk!();
            })
    }

    return (
        <FormModal visible={visible} 
                   title={title}
                   okText={intlMessage("common.save")}
                   cancelText={intlMessage("common.cancel")} 
                   onCancel={props.onCancel}
                   editMode={true}
                   onOk={props.onOk} form={form}
                   updateItem={updateItem}
                   violationsHolder={serverViolationsHolder}>

            <Form form={form} layout={"vertical"}>

                <Form.Item label={intlMessage("common.firstName")} {...formItemLayout}
                name={'firstName'}
                        initialValue={(user) ? user.firstName : undefined}
                        rules={[{required: false, message: intlMessage("required.firstName")}]
                    }>
                        <Input disabled={!appContext.user?.local} maxLength={50}/>
                </Form.Item>

                <Form.Item label={intlMessage("common.lastName")} {...formItemLayout}
                    name={'lastName'}
                        initialValue={(user) ? user.lastName : undefined}
                        rules={[{required: false, message: intlMessage("required.lastName")}]
                    }>
                        <Input disabled={!appContext.user?.local} maxLength={50}/>
                </Form.Item>

                <Form.Item label={intlMessage("common.email")} {...formItemLayout}
                    name={'email'}
                        initialValue={(user) ? user.email : undefined}
                        rules={[
                            {required: true, message: intlMessage("required.email")},
                            {type: "email", message: intlMessage("validation.email-bad-format")},
                            {validator: ValidationUtils.createServerValidator(serverViolationsHolder, 'EMAIL'), message: intlMessage("validation.email-bad-format")},
                            {validator: ValidationUtils.createServerValidator(serverViolationsHolder, 'UNIQUE'), message: intlMessage("validation.email-bad-format")}
                        ]}>
                        <Input disabled={true} autoComplete={"off"} maxLength={100}/>
                </Form.Item>

                <Form.Item label={intlMessage("common.company")} {...formItemLayout}
                    name={'company'}
                        initialValue={(user) ? user.company : undefined}
                        rules={[
                            {required: false, message: intlMessage("required.company")},
                        ]}>
                        <Input disabled={!appContext.user?.local} autoComplete={"off"} maxLength={200}/>
                </Form.Item>

                <Form.Item label={intlMessage("common.phoneNumber")} {...formItemLayout}
                    name={'phoneNumber'}
                        initialValue={(user) ? user.phoneNumber : undefined}
                        rules={[
                            {required: false, message: intlMessage("required.phoneNumber")},
                        ]}>
                        <Input disabled={!appContext.user?.local} autoComplete={"off"} maxLength={50}/>
                </Form.Item>

                <Form.Item
                    label={intlMessage("common.preferred-language-for-emails")}
                    {...formItemLayout}
                    extra={intlMessage("user-edit-profile.preferred-language-for-emails-extra", {defaultLang: applicationConfig.defaultLanguage.toUpperCase()})}
                    name={'language'}
                        initialValue={(user) ? user.language : undefined}
                    >
                        <Select style={{width: 200}} allowClear={true} placeholder={intlMessage("common.default-settings", {defaultLang: applicationConfig.mailPrimaryLanguage.toUpperCase()})}>
                            {appContext.applicationConfig?.languages?.map(lang =>
                                <Select.Option key={lang} value={lang}>{lang}</Select.Option>
                            )}
                        </Select>
                </Form.Item>
            </Form>
        </FormModal>
    );

}

export default ProfileModal;