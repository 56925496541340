import {useEffect} from "react";
import FilterParamsBuilder from "./common/filter/FilterParamsBuilder";
import SearchFormProps from "./common/SearchFormProps";

function ContactGroupSearchForm(props: SearchFormProps) {

    let prevProps: any = {quickSearch: ""};

    useEffect(() => {
        if (prevProps.quickSearch !== props.quickSearch || (prevProps.quickSearch === "" && props.quickSearch === "")) {
            handleSearch([]);
            prevProps.quickSearch = props.quickSearch;
        }
    }, [props.quickSearch]);


    return <></>;

    function handleSearch(values: any) {

        const filterParams = new FilterParamsBuilder().build();

        const filters: string[] = [];

        if (props.quickSearch) {
            filters.push(`$quickSearch$ ~ '${props.quickSearch}'`)
        }

        Object.keys(filterParams).forEach(key => {
            const value = values[key];
            const filterParam = filterParams[key];

            if (filterParam === undefined) {
                return;
            }

            const filterExpression = filterParam.build(value);

            if (filterExpression !== "") {
                filters.push(filterExpression);
            }
        });

        props.onSearch(filters.join(" and "), values);

    }

}

export default ContactGroupSearchForm;