import Axios from "axios";
import {Contact} from "../domain/Contact";
import PagedResult from "./common/PagedResult";
import QueryOptions from "./common/QueryOptions";

export class ContactService {

    private BASE_URL = "/user/contacts/";

    public getSimpleList(): Promise<Contact[]> {
        return Axios.get(`${this.BASE_URL}all`).then(value => {
            return value.data;
        })
    }

    public getList(queryOptions: QueryOptions): Promise<PagedResult<Contact[]>> {
        return Axios.get(`${this.BASE_URL}?${queryOptions.toQueryParams()}`).then(value => {
            return new PagedResult(value.data.total, value.data.data);
        })
    }

    public add(contact: Contact): Promise<any> {
        return Axios.post(this.BASE_URL, contact);
    }

    public update(contact: Contact): Promise<any> {
        return Axios.put(this.BASE_URL + contact.id, contact);
    }

    public delete(contact: Contact): Promise<any> {
        return Axios.delete(this.BASE_URL + contact.id);
    }

}
