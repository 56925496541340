export enum MultiFactorKeyType {
    FIDO2 = 'FIDO2',
    TOTP = 'TOTP'
}

/**
 * Key for Multi-factor authentication (MFA).
 */
export class MultiFactorKey {
    constructor(
        public id?: string,
        public type?: MultiFactorKeyType,
        public name?: string) {
        //
    }

    public static fromPOJO(data: any) {
        const multiFactorKey = new MultiFactorKey();

        multiFactorKey.id = data.id;
        multiFactorKey.type = data.type;
        multiFactorKey.name = data.name;

        return multiFactorKey;
    }
}
