
class PagedResult<T> {

    public total: number;

    public data: T;

    constructor(total: number, data: T) {
        this.total = total;
        this.data = data;
    }
}

export default PagedResult;
