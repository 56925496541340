import {Tooltip} from "antd";
import Paragraph, {ParagraphProps} from "antd/lib/typography/Paragraph";
import React, {useState} from "react";

const TooltipParagraph: React.FC<ParagraphProps> =
    ({
         children,
         ellipsis,
         ...props
     }) => {
        const [truncated, setTruncated] = useState(false);

        return (
            <Tooltip title={truncated ? children : undefined}>
                <Paragraph
                    {...props}
                    ellipsis={{onEllipsis: setTruncated}}
                >
                    {/* NOTE: Fragment is necessary to avoid showing the title */}
                    <>{children}</>
                </Paragraph>
            </Tooltip>
        );
    };

export default TooltipParagraph;
