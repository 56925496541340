enum LocalStorageNamespace {
    AuthLoggedIn = "Auth.LoggedIn",
    AuthUser = "Auth.User",
    JwtTokenExpireAt = "Auth.JwtTokenExpireAt",
    SettingsLanguage = "Settings.Language",
    PackagePassword = "Package.Password",
    PackageDetail = "Package.Detail",
    TablePage = "Table.Page",
    TablePageSize = "Table.PageSize",
    TableOrderBy = "Table.OrderBy",
    TableFilter = "Table.Filter",
    TableSearchFormValues = "Table.SearchFormValues",
    TermsAndDisclaimer = "Auth.TermsAndDisclaimer",
    LocationLastPath = "Location.lastPath",
}

export default LocalStorageNamespace;
