import {TeamOutlined, UserOutlined} from "@ant-design/icons";
import {Checkbox, DatePicker, Form, Input, InputRef, message, Modal, Radio, Select} from "antd";
import {useForm} from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import moment from 'moment';
import {FormEvent, useContext, useEffect, useRef, useState} from "react";
import {AppContextContext, ContactGroupServiceContext, ContactServiceContext, PackageServiceContext} from "../Contexts";
import {Contact} from "../domain/Contact";
import {ContactGroup} from "../domain/ContactGroup";
import {Package, PackageAccessType, RecipientType} from "../domain/Package";
import {Recipient} from "../domain/Recipient";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {FormModalProps} from "../sal-ui/FormModal";
import {ServerConstraintViolationsHolder} from "../sal-ui/ServerConstraintViolations";
import ValidationUtils from "../service/common/ValidationUtils";
import FormatUtils from "../utils/FormatUtils";
import ServerViolations from "./ServerViolations";

interface IProps extends FormModalProps {
    model: Package;
}

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

function PackageModal(props: IProps) {
    const forceUpdate = useForceUpdate();

    const [form] = useForm();
    const intlMessage = useIntlMessage('multi-factor-key');
    const appContext = useContext(AppContextContext);
    const packageService = useContext(PackageServiceContext);
    const contactService = useContext(ContactServiceContext);
    const contactGroupService = useContext(ContactGroupServiceContext);
    const applicationConfig = appContext.applicationConfig;

    const [switchPassword, setSwitchPassword] = useState<boolean>();
    const [model, setModel] = useState<Package>();
    const [selectedRecipients, setSelectedRecipients] = useState<any[]>([]);
    const [recipientEmailAddressBookVisible, setRecipientEmailAddressBookVisible] = useState<boolean>();
    const [accessType, setAccessType] = useState<PackageAccessType>();
    const [recipientType, setRecipientType] = useState<RecipientType>(RecipientType.REGULAR);
    const [inProgress, setInProgress] = useState<boolean>();

    const [userAddressBook, setUserAddressBook] = useState<Contact[]>([]);
    const [userContactGroups, setUserContactGroups] = useState<ContactGroup[]>([]);
    const [downloadPasswordRequired, setDownloadPasswordRequired] = useState<boolean>(false);
    const downloadPasswordRef = useRef<InputRef>(null);

    useEffect(() => {

        if ((!props.model) || !props.visible) return;

        if (props.model.id) {
            loadPackage();
        }

        if (!props.editMode) {
            loadContacts();
        }
    }, [props, props.model]);

    const {visible, title} = props;
    let serverViolations = serverViolationsHolder.violations.constraintViolations;

    return (
        <Modal destroyOnClose={true} visible={visible} title={title} confirmLoading={inProgress}
               width={700}
               okText={intlMessage("common.save")}
               cancelText={intlMessage("common.cancel")} onCancel={props.onCancel}
               onOk={handleSubmit} maskClosable={false}>
            <ServerViolations constraintViolations={serverViolations}/>

            {props.editMode && model && renderEditForm()}
            {!props.editMode && renderAddRecipientForm()}
        </Modal>
    );


    /**
     * Uprava package
     * @param values
     */
    function updateItem(values: any): Promise<any> {

        // pole, ktere se nemenili neaktualizujeme + zjistujeme, jestli vubec je potreba delat update
        let update = false;
        for (const key in values) {
            if (!form.isFieldTouched(key)) {
                values[key] = undefined;
            } else {
                update = true;
            }
        }

        // pokud se zmenilo nastaveni hesla
        if (!model!.passwordProtected && props.model.passwordProtected) {
            values.downloadPassword = null;
            values.changePassword = true;
            update = true;
        }
        if (model!.passwordProtected && form.isFieldTouched("downloadPassword")) {
            values.changePassword = true;
        }

        if (update) {
            return packageService!.update(props.model.id, values)
                .then(() => {
                    message.success(intlMessage('package-edit.updated', {name: (model!.name ? model!.name : model!.id)}));

                    props.onOk!();
                })
        } else {
            return new Promise<any>(() => {
                message.success(intlMessage('package-edit.no-update', {name: (model!.name ? model!.name : model!.id)}));

                props.onOk!();
            });
        }
    }

    function addRecipients(values: any): Promise<any> {
        const tmp = values.recipientEmails!.toString().split(/[,\n;]/);

        let blind = false;
        let contributor = false;

        switch (values.recipientType) {
            case RecipientType.REGULAR:
                blind = false;
                contributor = false;
                break;
            case RecipientType.BLIND:
                blind = true;
                contributor = false;
                break;
            case RecipientType.CONTRIBUTOR:
                blind = false;
                contributor = true;
                break;

        }

        const recipients: Recipient[] = [];
        tmp.forEach((value: string) => {
            if (value.trim()) {
                recipients.push({email: value.trim(), blind, contributor});
            }
        })

        return packageService!.addRecipients(props.model.id, recipients)
            .then(() => {
                    message.success(intlMessage('package-add-recipients.done', {name: values.username}));

                    props.onOk!();
                },
                reason => {
                    const violations = reason.response.data.constraintViolations;

                    if (violations.recipientEmails && violations.recipientEmails.CUSTOM_JSON!) {
                        const error = violations.recipientEmails!.CUSTOM_JSON;
                        const jsonError = JSON.parse(error.message);

                        form.setFields([{
                            name: "recipientEmails",
                            value: form.getFieldValue("recipientEmails"),
                            errors: [new Error(intlMessage("validation." + jsonError.key + ".message", {email: jsonError.message})).message]
                        }
                        ]);

                        return reason;
                    }
                });
    }

    function validationErrorHandler(error: any) {
        if (error && error.response && error.response.data && ValidationUtils.isConstraintViolations(error.response.data)) {
            serverViolationsHolder.violations = error.response.data;

            form.validateFields();
        }

        return Promise.reject();
    }

    function progressStarted() {
        setInProgress(true);

    }

    function progressFinished() {
        setInProgress(false);
    }

    function handleSubmit(e: FormEvent) {
        e.preventDefault();

        form.validateFields().then((value) => {

                if (props.editMode) {
                    progressStarted();
                    updateItem(form.getFieldsValue())
                        .catch(validationErrorHandler)
                        .finally(() => progressFinished());
                } else {
                    progressStarted();
                    addRecipients(form.getFieldsValue())
                        .catch(validationErrorHandler)
                        .finally(() => progressFinished());
                }
            }, reason => {
                console.log(reason);
                return reason;
            }
        )
    }

    // nacte adresar
    function loadContacts() {
        if (appContext!.user) {
            contactService!.getSimpleList().then((contactList: Contact[]) => {
                setUserAddressBook(contactList);
            });

            contactGroupService!.getSimpleList().then((contactGroupList: ContactGroup[]) => {
                setUserContactGroups(contactGroupList);
            });
        }
    }

    function loadPackage(): Promise<any> {
        return packageService!.get(props.model.id!).then(
            aPackage => {
                setModel(aPackage);
                setSwitchPassword(aPackage.passwordProtected);
            });
    }

    function renderEditForm() {

        return <Form form={form} layout={"vertical"}>
            <Form.Item label={intlMessage("package.label.name")}
                       name={'name'} initialValue={(model) ? model.name : undefined}>

                <Input type="text" name="name" maxLength={100}/>

            </Form.Item>

            <Form.Item label={intlMessage("package.label.note")} name={"note"}
                       initialValue={(model) ? model.note : undefined}>
                <TextArea name="note" autoSize={{minRows: 1, maxRows: 6}} maxLength={10000}/>
            </Form.Item>


            {model!.accessType !== "BRIEFCASE" &&
                <>
                    <div className="ant-row ant-form-item checkbox">
                        <div className="ant-col-24">
                            <Checkbox
                                checked={model!.passwordProtected}
                                disabled={props.model.dataEncryptedWithPassword}
                                data-test-id={"downloadPasswordCheckbox"}
                                onChange={(e) => {
                                    setSwitchPassword(e.target.checked);

                                    setModel((prevState: any) => {
                                        return (
                                            {
                                                ...prevState,
                                                passwordProtected: e.target.checked
                                            }
                                        );
                                    });

                                    if (e.target.checked) {
                                        setTimeout(() => downloadPasswordRef.current?.focus(), 0);
                                    }

                                    form.resetFields(["downloadPassword", "encryptDataWithPassword"]);

                                    setTimeout(forceUpdate, 100);
                                }}>
                                {intlMessage("package.label.password")}
                            </Checkbox>
                        </div>

                        {!props.model.dataEncryptedWithPassword && switchPassword &&

                            <>
                                <Form.Item
                                    name={"downloadPassword"}
                                    className={'download-password'}
                                    rules={[
                                        {min: appContext.applicationConfig?.packagePasswordRequirements.minLength, message: intlMessage("validation.password-too-short", {length: appContext.applicationConfig?.packagePasswordRequirements.minLength})},
                                        {required: downloadPasswordRequired, message: intlMessage("required.password")},
                                        {validator: serverViolationsHolder.createServerValidator('CUSTOM')}
                                    ]}>
                                    <div className="ant-col-24 padding-bottom-12px">
                                        <Input.Password autoComplete={"none"} maxLength={30} ref={downloadPasswordRef} onChange={e => {
                                            setTimeout(forceUpdate, 100)
                                        }}/>
                                    </div>
                                </Form.Item>
                            </>
                        }
                    </div>

                </>
            }
            <Form.Item label={intlMessage("package.label.ttl")} name={"cleanExpiration"} initialValue={(model) ? moment(model.cleanExpiration) : undefined}>
                <DatePicker id="cleanExpiration" disabledDate={(date: any) =>
                    date && (
                        date < new Date() ||
                        (!!model!.minCleanExpiration && date < new Date(model!.minCleanExpiration)) ||
                        (!!model!.maxCleanExpiration && date > new Date(model!.maxCleanExpiration))
                    )
                }/>
            </Form.Item>

        </Form>;
    }

    function renderAddRecipientForm() {

        const allowedDomainsRegExp = "(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])";
        const emailRegExpString = "(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")";

        const {Option} = Select;

        const filteredAddressBook: Contact[] = [...userAddressBook];

        userContactGroups!.forEach(group => {
            filteredAddressBook.push({email: group.name!, name: undefined, group: true});
        })

        return (
            <Form form={form} layout={"vertical"}>
                <Form.Item
                    id={"recipients"}
                    label={intlMessage("package.label.recipients")}
                    name={"recipientEmails"}

                    normalize={(value, prevValue, allValues) => normalizeRecipients(value, prevValue, allValues)}
                    rules={[
                        {
                            required: true,
                            message: intlMessage("required.recipients")
                        },
                        {
                            pattern: new RegExp("^(" + emailRegExpString + "@(" + allowedDomainsRegExp + ")[,\n;]{0,1}[ ]*)+$"),
                            message: intlMessage("validation.recipients-bad-format")
                        },
                        {
                            pattern: new RegExp("^(?!(^|([\\s\\S]*[,\\n;]{1}[ ]*))(" + emailRegExpString + "@(" + allowedDomainsRegExp + "))[\\s\\S]*[,\n;]{1}[ ]*\\3)[\\s\\S]*$"),
                            message: intlMessage("validation.recipients-contain-duplicates")
                        }

                    ]}
                >
                    <Select mode="tags" size={'middle'} style={{width: '100%'}} tokenSeparators={[',']}
                            notFoundContent={null}
                            onSearch={fetchAddressbook}
                            open={recipientEmailAddressBookVisible}
                            filterOption={filterRecipientEmailOption}
                            dropdownClassName={"recipientEmailsOptions"}
                            defaultActiveFirstOption={true}
                            optionLabelProp={"value"}
                            autoFocus={true}
                    >
                        {filteredAddressBook.map((contact: any) => (
                            <Option key={contact.email} value={contact.email} title={contact.name ? contact.name : contact.email}>
                                <div className={"contact-icon"}>
                                    {contact.group ? <TeamOutlined/> : <UserOutlined/>}
                                </div>
                                <div className={"contact-text"}>
                                    <span>{contact.name && <>{contact.name}<br/></>}{contact.email}</span>
                                </div>
                            </Option>
                        ))}
                    </Select>

                </Form.Item>

                <Form.Item name={"recipientType"}
                           className={"checkbox"}
                           label={intlMessage("package.recipient-type")}
                           initialValue={RecipientType.REGULAR}>

                    <Radio.Group data-test-id={"accessType"} buttonStyle="solid" onChange={(e) => {
                        setRecipientType(e.target.value);
                    }}>
                        <Radio.Button value={RecipientType.REGULAR}>{intlMessage("package.recipient-type.REGULAR")}</Radio.Button>
                        <Radio.Button value={RecipientType.BLIND}>{intlMessage("package.recipient-type.BLIND")}</Radio.Button>
                        {appContext.user?.userConfig?.cooperativePackagesAllowed &&
                            <Radio.Button value={RecipientType.CONTRIBUTOR}>{intlMessage("package.recipient-type.CONTRIBUTOR")}</Radio.Button>
                        }
                    </Radio.Group>
                </Form.Item>

                <div className={"ant-legacy-form-extra"}>{intlMessage("package.placeholder.recipients")}</div>
            </Form>
        );
    }

    function filterRecipientEmailOption(inputValue: any, option: any) {
        const key: string = option.key;
        const name: string = option.props.title;
        let displayItem = false;

        // hledani podle klice
        if (key.toLowerCase().includes(inputValue.toLowerCase())) {
            displayItem = true;
        }

        // hledani podle jmena
        if (name && name.toLowerCase().includes(inputValue.toLowerCase())) {
            displayItem = true;
        }

        if (displayItem) {
            return !selectedRecipients!.find(value => {
                return value.trim() === key.trim();
            });
        } else {
            return false;
        }
    }

    function fetchAddressbook(value: any) {

        if (value && value.length > 0) {
            setRecipientEmailAddressBookVisible(true);
        } else {
            setRecipientEmailAddressBookVisible(false);
        }
    }

    function normalizeRecipients(valueArray: any[], prevArray: any[], all: any) {
        FormatUtils.expandGroup(valueArray, userContactGroups);

        const tmp = FormatUtils.normalizeRecipientsFromSelect(valueArray, prevArray, null);

        // removeOwnEmailAddress(tmp!);

        setRecipientEmailAddressBookVisible(false);
        setSelectedRecipients(tmp ? tmp : []);

        return tmp;
    }
    /*
    function handleChangeRecipientEmail(value: string[]) {

        FormatUtils.expandGroup(value, userContactGroups);

        const tmp = FormatUtils.normalizeRecipientsFromSelect(value, value, null);

        setRecipientEmailAddressBookVisible(false);
        setSelectedRecipients(tmp ? tmp : []);

        // opozdena validace, jinak to nezafunguje dobre
        setTimeout(form.validateFields, 100, {force: true});
    }
*/

}

export default PackageModal;