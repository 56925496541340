import {UploadFile} from "antd/lib/upload/interface";
import Axios from "axios";
import {PackageFile} from "../domain/Package";

export class PackageFileService {

    private BASE_URL = "/user/upload-file/";

    private defaultConfig = {}

    public upload(packageId: string, fileId: string, entity: UploadFile, config: any, uploadToken?: string): Promise<any> {

        if (!config) {
            config = this.defaultConfig;
        }

        if (uploadToken) {
            config.headers = {'X-Sofie-Upload-Token': uploadToken};
        }

        const CancelToken = Axios.CancelToken;
        const cancelSource = CancelToken.source();

        config.cancelToken = cancelSource.token;
        config.source = cancelSource;

        config.headers = {
            ...config.headers,
            "Content-Type": "text/html"
        };

        return Axios.post(this.BASE_URL + packageId + "/" + fileId, entity, config);
    }

    public addFile(packageId: string, fileId: string, entity: UploadFile, config: any): Promise<any> {

        if (!config) {
            config = this.defaultConfig;
        }

        const CancelToken = Axios.CancelToken;
        const cancelSource = CancelToken.source();

        config.cancelToken = cancelSource.token;
        config.source = cancelSource;

        config.headers = {
            ...config.headers,
            "Content-Type": "text/html"
        };

        return Axios.post(this.BASE_URL + packageId + "/add-file/" + fileId, entity, config);
    }

    public delete(entity: PackageFile, deleteSafeCopy: boolean | undefined): Promise<any> {

        if (deleteSafeCopy === undefined) {
            return Axios.delete(`/user/packages/${entity.packageId}/file/${entity.id}`);
        } else {
            return Axios.delete(`/user/packages/${entity.packageId}/file/${entity.id}?deleteSafeCopy=${deleteSafeCopy}`);
        }

    }

    public renameFile(entity: PackageFile, filename: string): Promise<any> {
        return Axios.post(`/user/packages/${entity.packageId}/rename-file/${entity.id}`, {filename});
    }


    public keepAlive() {
        return Axios.get(this.BASE_URL + "in-progress", {params: {withoutProgressBar: true}});
    }

}
