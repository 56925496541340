import {Row} from 'antd';
import React, {useContext} from "react";
import {AppContextContext} from "../Contexts";
import {useIntlMessage} from "../sal-ui/createIntlMessage";


function NoMatch() {
    const appContext = useContext(AppContextContext);
    const applicationConfig = appContext.applicationConfig!;
    const intlMessage = useIntlMessage("lang");

    return (
        <>
            <Row justify={"space-around"} align={"middle"}>
                <div style={{textAlign: "center", position: "fixed", width: "100%", top: "40%"}}>
                    <div style={{fontSize: "30px", paddingBottom: "32px"}}>{intlMessage('common.page-not-found')}</div>
                    <div>{intlMessage('common.you-can-continue')} <a href={applicationConfig.baseDownloadUri}>{intlMessage('common.here')}</a></div>
                </div>
            </Row>
        </>
    )

}

export default NoMatch;