import FilterParam from "./FilterParam";
import moment from "moment";

class SimpleOperatorFilterParam implements FilterParam {

    constructor(public operator: string, public name: string, public stringToModelConverter?: (value: string) => any) {
        //
    }

    public build(value: any) {
        if (value === undefined || value === '') {
            return "";
        }

        const paramValue = (moment.isMoment(value)) ? value.valueOf() : value;

        return `${this.name} ${this.operator} '${paramValue}'`;
    }

}

export default SimpleOperatorFilterParam;
