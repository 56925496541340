import {Checkbox, Form, Input, InputRef, message, Tooltip} from "antd";
import {useContext, useEffect, useRef} from "react";
import {FormModal, FormModalDelegate, FormModalProps} from "../sal-ui/FormModal";
import {useForm} from "antd/lib/form/Form";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {AppContextContext, PackageServiceContext} from "../Contexts";
import FormatUtils from "../utils/FormatUtils";
import {QuestionCircleOutlined} from "@ant-design/icons";
import styles from "./DownloadPackagePasswordModal.module.css";
import {DownloadTokenInfo} from "../domain/DownloadTokenInfo";

interface IProps extends FormModalProps {
    packageId: string,
    onDownloadTokenAcquired: (token: DownloadTokenInfo, rememberPassword: boolean) => void;
}

/**
 * Modální dialog s výzvou pro zadání hesla zásilky.
 * Určené pro zásilky šifrováné heslem - zobrazí se před stažením souboru.
 */
function DownloadPackagePasswordModal(props: IProps) {
    const appContext = useContext(AppContextContext);
    const packageService = useContext(PackageServiceContext);
    const [form] = useForm();
    const intlMessage = useIntlMessage('download-package-password-modal');
    const formModalRef = useRef<FormModalDelegate>(null);
    const passwordRef = useRef<InputRef>(null);

    const formItemLayout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    useEffect(() => {
        setTimeout(() => passwordRef.current?.focus(), 100)
    }, []);

    return (
        <FormModal
            title={intlMessage("package-detail.decryption-password-required")}
            visible={props.visible}
            form={form}
            editMode={true}
            updateItem={updateItem}
            okText={intlMessage("common.decrypt")}
            cancelText={intlMessage("common.cancel")}
            onCancel={props.onCancel}
            onOk={props.onOk}
            ref={formModalRef}
            maskCloseable={false}>

            <Form form={form} onFinish={() => formModalRef.current?.onSubmit()}>
                <Form.Item
                    {...formItemLayout}
                    name={"password"}
                    label={intlMessage("common.password")}
                    rules={[{required: true, message: intlMessage("required.password")}]}>

                    <Input type="password" name="password" maxLength={30} ref={passwordRef}/>

                </Form.Item>

                <Form.Item
                    {...formItemLayout}
                    name={"rememberPassword"}
                    className={styles['light-label']}
                    valuePropName={"checked"}>

                    <Checkbox>
                        {intlMessage("package-detail.remember-package-password", {duration: FormatUtils.formatDuration(appContext.applicationConfig?.rememberPasswordDuration!)})}

                        <Tooltip title={intlMessage("package-detail.remember-package-password-tooltip")} className={styles['tooltip']}>
                            <QuestionCircleOutlined/>
                        </Tooltip>
                    </Checkbox>

                </Form.Item>
            </Form>
        </FormModal>
    );

    function updateItem(values: any): Promise<any> {
        return packageService.get(props.packageId, values.password).then(aPackage => {
            props.onDownloadTokenAcquired(aPackage.downloadTokenInfo!, values.rememberPassword);
        }, reason => {
            let errorMsg;

            if (reason.response.status === 403 && reason.response.data !== "login.failed") {
                errorMsg = reason.response.data;
            } else {
                errorMsg = intlMessage('error.unexpected-response');
            }

            message.error(errorMsg);
        });
    }

}

export default DownloadPackagePasswordModal;
