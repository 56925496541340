import {Alert, Button, Checkbox, Col, Form, Input, Row, Tooltip} from "antd";
import {useContext, useState} from "react";
import {PackagePasswordType} from "../domain/Package";
import styles from "./DownloadPackagePasswordModal.module.css";
import FormatUtils from "../utils/FormatUtils";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {useForm} from "antd/es/form/Form";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {AppContextContext} from "../Contexts";

interface IProps {
    onSubmit: (password: string, rememberPassword: boolean) => Promise<any>;
    enteredInvalidPassword: boolean;
    serverErrorMessage: string;
    passwordType: PackagePasswordType;
}

function PackagePasswordRequired(props: IProps) {
    const appContext = useContext(AppContextContext);
    const [inProgress, setInProgress] = useState(false);
    const intlMessage = useIntlMessage("package-password-required");
    const [form] = useForm();

    const formItemLayout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    let titleId: string;
    let textId: string;
    let labelId: string;
    let requiredId: string;

    if (props.passwordType === PackagePasswordType.Request) {
        titleId = 'package-detail.password-required.request.title';
        textId = 'package-detail.password-required.request.text';
        labelId = 'package-detail.password-required.request.label';
        requiredId = 'package-detail.password-required.request.required';
    } else {
        // PackagePasswordType.Download + PackagePasswordType.Forward
        titleId = 'package-detail.password-required.download.title';
        textId = 'package-detail.password-required.download.text';
        labelId = 'package-detail.password-required.download.label';
        requiredId = 'package-detail.password-required.download.required';
    }

    return (
        <Row justify={"space-around"} align={"middle"} className={"login-form"}>
            <Col id={"login-form"}>

                <Row>
                    <h1>{intlMessage(titleId)}</h1>
                </Row>

                <div className={"package-access-info"}>
                    {intlMessage(textId)}
                </div>

                {props.enteredInvalidPassword ?
                    <div className={"package-access-info"}>
                        <Alert type="error" message={props.serverErrorMessage}/>
                    </div>
                    : ""}

                <Form form={form} onFinish={onFinish} layout={"vertical"} className={"package-password-required"}>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                {...formItemLayout}
                                name={"password"}
                                label={intlMessage(labelId)}
                                rules={[{required: true, message: intlMessage(requiredId)}]}>
                                <Input type="password" autoFocus={true} maxLength={30}/>
                            </Form.Item>

                            {
                                appContext.user &&

                                <Form.Item
                                    {...formItemLayout}
                                    name={"rememberPassword"}
                                    className={styles['light-label']}
                                    valuePropName={"checked"}>

                                    <Checkbox>
                                        {intlMessage("package-detail.remember-package-password", {duration: FormatUtils.formatDuration(appContext.applicationConfig?.rememberPasswordDuration!)})}

                                        <Tooltip title={intlMessage("package-detail.remember-package-password-tooltip")} className={styles['tooltip']}>
                                            <QuestionCircleOutlined/>
                                        </Tooltip>
                                    </Checkbox>

                                </Form.Item>
                            }
                        </Col>
                    </Row>

                    <Button type="primary" style={{width: "100%", height: "50px"}} size={"large"} loading={inProgress} htmlType="submit">
                        {intlMessage("common.submit")}
                    </Button>
                </Form>
            </Col>
        </Row>
    );

    function onFinish(values: any) {
        setInProgress(true);

        props.onSubmit(values.password, values.rememberPassword).finally(() => setInProgress(false));
    }
}

export default PackagePasswordRequired;
