import {LoadingOutlined} from "@ant-design/icons";
import {Alert, Button, Col, Form, Input, Row} from "antd";
import {useForm} from "antd/lib/form/Form";
import {useContext, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router";
import {AppContext} from "../AppContext";
import {AppContextContext, AuthServiceContext, UserServiceContext} from "../Contexts";
import {User} from "../domain/User";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {ServerConstraintViolationsHolder} from "../sal-ui/ServerConstraintViolations";
import {AuthService} from "../service/AuthService";
import {UserService} from "../service/UserService";
import {routesMap} from "./Routes";


enum Step {
    EnterUsername,
    EnterToken,
    EnterPassword
}

interface IProps {
    appContext?: AppContext;
    userService?: UserService;
    authService?: AuthService,
    switchMode: (e: any) => void;
}

function ResetPassword(props: IProps) {

    const intlMessage = useIntlMessage("login-page");
    const appContext = useContext(AppContextContext);
    const authService = useContext(AuthServiceContext);
    const userService = useContext(UserServiceContext);
    const history = useHistory();
    const [form] = useForm();
    const location = useLocation();
    const [currentStep, setCurrentStep] = useState<Step>();
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [invalidToken, setInvalidToken] = useState<boolean>(false);
    const [token, setToken] = useState<string>();
    const [tokenResetRequested, setTokenResetRequested] = useState<boolean>(false);
    const isOnResetPasswordTokenPage = history.location.pathname === "/reset-password-token";
    const [serverViolationsHolder] = useState(new ServerConstraintViolationsHolder());
    const qs = require('qs');
    const [serverErrorMessage, setServerErrorMessage] = useState<string|undefined>();

    useEffect(() => {
        if (location.pathname === "/reset-password-token") {
            setCurrentStep(Step.EnterToken);

            const params = qs.parse(location.search, {ignoreQueryPrefix: true});

            // pokud mame token, zkusime ho odeslat
            if (params && params.token) {
                setToken(params.token);

                setTimeout(handleSubmitToken, 2000, params);
            }

        } else {
            setCurrentStep(Step.EnterUsername);
        }
    }, [props]);

    return (
        <Row justify={"space-around"} align={"middle"} className={"login-form"}>
            <Col id={"login-form"}>
                <h1>{intlMessage('login.reset-password-title')}</h1>

                {renderFormForCurrentStep()}

                {!isOnResetPasswordTokenPage ?
                    <div className={"login-back"}>
                        <a onClick={props.switchMode}>{intlMessage('login.back-to-login')}</a>
                    </div>
                    : ''}
            </Col>
        </Row>
    )

    function renderFormForCurrentStep() {
        switch (currentStep) {
            case Step.EnterUsername:
                return renderEnterUsernameForm();
            case Step.EnterToken:
                return renderEnterTokenForm();
            case Step.EnterPassword:
                return renderEnterPasswordForm();
            default:
                return "";
        }
    }

    function renderEnterUsernameForm() {

        return (
            <div>
                <div className={"login-form-message"}>{intlMessage('login.reset-password-text')}</div>

                {serverErrorMessage &&
                    <Alert message={serverErrorMessage} type="error" />
                }

                <Form onFinish={handleSubmitUsername} layout={"vertical"} className="login-form" form={form}>
                    <Form.Item label={intlMessage("common.username")} name={"username"} rules={[{required: true, message: intlMessage("required.username")}]}>
                        <Input type="text" name="username" autoFocus={true} maxLength={50}/>
                    </Form.Item>
                    <Button type="primary" htmlType="submit" loading={inProgress} style={{width: "100%"}} size="large">{intlMessage("login.send-reset-password-token")}</Button>
                </Form>
            </div>
        )
    }

    function renderEnterTokenForm() {
        if (invalidToken) {
            return <div className={"login-form-message"}>{intlMessage('login.reset-password-invalid-token')}</div>;
        }

        return (
            <div>
                {(tokenResetRequested) ?
                    <div className={"login-form-message"}>{intlMessage('login.reset-password-enter-token')}</div>
                    :
                    <>
                        <div className={"login-form-message"}>{intlMessage('login.reset-password-token-validation')}</div>
                        <div className={"login-form-reset-password-token-validation"}>
                            <LoadingOutlined/>
                        </div>
                    </>
                }
            </div>
        )
    }

    function renderEnterPasswordForm() {

        return (
            <div>
                {(invalidToken) ?
                    <Alert style={{marginBottom: 24}} message={intlMessage('login.reset-password-invalid-token')} type="error" showIcon={false}/> :
                    <div className={"login-form-message"}>{intlMessage('login.enter-new-password-text')}</div>
                }

                <Form onFinish={handleSubmitPassword} layout={"vertical"} className="login-form" form={form}>
                    <Form.Item label={intlMessage("common.new-password")} name={"password"} rules={
                        [
                            {required: true, message: intlMessage("required.password")},
                            {
                                validator: serverViolationsHolder.createServerValidator('CUSTOM'),
                                message: serverViolationsHolder.violations.constraintViolations.password?.CUSTOM.message
                            }
                        ]
                    }>
                        <Input.Password autoComplete={"off"} maxLength={30} name="password" autoFocus={true}/>
                    </Form.Item>

                    <Button type="primary" htmlType="submit" loading={inProgress} style={{width: "100%"}} size="large">{intlMessage("login.set-new-password")}</Button>
                </Form>
            </div>
        )
    }


    function handleSubmitUsername(values: any) {
        setInProgress(true);
        setToken(undefined);
        setTokenResetRequested(true);

        userService.resetPassword(values.username)
            .then(() => {
                    setCurrentStep(Step.EnterToken);
                    setServerErrorMessage(undefined);
                },
                error => {
                    setServerErrorMessage(error.response.data);
                })
            .finally(() => setInProgress(false));
    }

    function handleSubmitToken(values: any) {
        setInProgress(true);

        userService.validateResetPasswordToken(values.token)
            .then(
                () => {
                    setToken(values.token);
                    setInvalidToken(false);
                    setCurrentStep(Step.EnterPassword);
                },
                () => {
                    setInvalidToken(true);
                }
            )
            .finally(() => setInProgress(false));
    }

    function handleSubmitPassword(values: any) {
        setInProgress(true);

        userService.resetNewPassword(token!, values.password)
            .then(
                (user) => {
                    authService.authenticate(user.username, values.password)
                        .then(
                            (authenticatedUser: User) => {
                                setToken(undefined);
                                setTokenResetRequested(false);

                                appContext.user = authenticatedUser;

                                history.replace(routesMap.PackagesInbox.path);
                            },
                            error => {
                                if (error?.response?.status === 401 && error?.response?.headers['x-sofie-response-reason'] === 'Multi-factor authentication required') {
                                    history.replace('/login?mfa');
                                } else {
                                    serverViolationsHolder.handleServerError(error, form);
                                }
                            });
                },
                error => serverViolationsHolder.handleServerError(error, form)
            )
            .finally(() => setInProgress(false));
    }

}

export default ResetPassword;
