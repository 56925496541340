import {makeObservable, observable} from "mobx";
import ApplicationConfig from "./components/common/ApplicationConfig";
import {User} from "./domain/User";
import {CSPConfig} from "antd/lib/config-provider";

export class AppContext {

    @observable public language: string = "";

    @observable public user?: User;

    @observable public applicationConfig?: ApplicationConfig;

    public cspConfig?: CSPConfig;

    @observable public disableLangSelector?: boolean = false;

    constructor() {
        makeObservable(this)
    }

}
