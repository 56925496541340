import Icon, {PlusOutlined, ReloadOutlined} from '@ant-design/icons';
import {Button} from "antd";
import Cookies from "js-cookie";
import {useContext, useEffect, useState} from "react";
import {Redirect, useHistory} from "react-router";
import {Link} from "react-router-dom";
import {AppContextContext, PackageServiceContext} from "../Contexts";
import {UserPermission} from "../domain/User";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {useTableHandler} from "../sal-ui/TableHandler";
import BriefcaseSearchForm from "./BriefcaseSearchForm";
import {IconSendPackage} from "./common/CustomIcons";
import CooperativeSearchForm from "./CooperativeSearchForm";
import {DocumentTitle} from "./DocumentTitle";
import PackagesInboxSearchForm from "./PackagesInboxSearchForm";
import PackageList from "./PackageList";
import {routesMap} from "./Routes";

function Cooperative() {

    const appContext = useContext(AppContextContext);
    const packageService = useContext(PackageServiceContext);
    const applicationConfig = appContext.applicationConfig;
    const intlMessage = useIntlMessage("contact-list");
    const tableHandler = useTableHandler("uploaded desc", {reloadFunction: reload, persistentIdent: "Cooperative"});
    const history = useHistory();
    const [data, setData] = useState<any>();

    useEffect(() => {
        const redirectLink = Cookies.get("Load-package");
        if (redirectLink) {
            Cookies.remove("Load-package", {path: "/"});
            history.push(redirectLink);
        }

    }, []);

    return (
        <DocumentTitle title={`${applicationConfig!.title}: ${intlMessage('packages-inbox-outbox.cooperative-title')}`}>
            {appContext.user && !(applicationConfig?.cooperativePackages === "YES" || applicationConfig?.cooperativePackages === "PERMISSION_BASED") && <Redirect to={routesMap.Dashboard.path}/>}
            <div>
                <h1>{intlMessage("packages-inbox-outbox.cooperative-title")}</h1>

                <CooperativeSearchForm onSearch={tableHandler.onSearchSubmit}/>

                <div className={"actions"}>
                    <Button type="ghost" icon={<ReloadOutlined/>} onClick={reload}/>
                </div>

                <PackageList tableHandler={tableHandler} data={data} reload={reload} tableType={'COOPERATIVE'}/>
            </div>
        </DocumentTitle>
    );

    function reload() {
        return packageService.getCooperative(tableHandler.queryOptions)
            .then((value: any) => {
                    tableHandler.updateTotal(value.total);
                    setData(value.data);
                }
            )
    }

}

export default Cooperative;
