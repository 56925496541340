import moment from 'moment';
import {IntlShape} from "react-intl";
import {ContactGroup} from "../domain/ContactGroup";

class FormatUtils {

    private static readonly KILOBYTE = 1024;
    private static readonly MEGABYTE = FormatUtils.KILOBYTE * 1024;
    private static readonly GIGABYTE = FormatUtils.MEGABYTE * 1024;

    /*
    public static formatBytes(bytes?: number): string {
        if (bytes === undefined) {
            return "? B";
        }

        if (bytes >= this.GIGABYTE) {
            return `${(bytes / this.GIGABYTE).toFixed(2).replace(/\.00$/, "")} GiB`;
        } else if (bytes >= this.MEGABYTE) {
            return `${(bytes / this.MEGABYTE).toFixed(2).replace(/\.00$/, "")} MiB`;
        } else if (bytes >= this.KILOBYTE) {
            return `${(bytes / this.KILOBYTE).toFixed(0)} kiB`;
        } else {
            return `${bytes} B`;
        }
    }
*/
    public static formatBytes(bytes?: number, showUnit?: boolean): string {
        if (showUnit === undefined) {
            showUnit = true;
        }

        if (bytes === undefined) {
            const unit = showUnit ? "B" : "";

            return "?\u00A0" + unit;
        }

        if (bytes >= this.GIGABYTE) {
            const unit = showUnit ? "GiB" : "";

            return `${(bytes / this.GIGABYTE).toFixed(2).replace(/\.00$/, "")}\u00A0` + unit;
        } else if (bytes >= this.MEGABYTE) {
            const unit = showUnit ? "MiB" : "";

            return `${(bytes / this.MEGABYTE).toFixed(2).replace(/\.00$/, "")}\u00A0` + unit;
        } else if (bytes >= this.KILOBYTE) {
            const unit = showUnit ? "kiB" : "";

            return `${(bytes / this.KILOBYTE).toFixed(0)}\u00A0` + unit;
        } else {
            const unit = showUnit ? "B" : "";

            return `${bytes}\u00A0` + unit;
        }
    }

    public static formatDate(datetime: string, millis?: boolean): string {
        if (datetime === undefined) {
            return '';
        }

        datetime = this.toLocalTimezone(datetime);

        const ret = datetime.toString().replace("T", " ");

        if (!millis) {
            return ret.replace(/\.[0-9]+/, "");
        } else {
            return ret;
        }
    }

    public static formatLocalizedDateTime(datetime: string): string {
        if (datetime === undefined) {
            return '';
        }

        datetime = this.toLocalTimezone(datetime);

        return moment(datetime).format('l LTS');
    }

    public static formatOnDateAtTime(intl: IntlShape, datetime: string): string {
        if (datetime === undefined) {
            return '';
        }

        datetime = this.toLocalTimezone(datetime);

        const momentDateTime = moment(datetime);

        return intl.formatMessage({id: 'on-date-at-time'}, {date: momentDateTime.format("l"), time: momentDateTime.format("LTS")});
    }

    public static toLocalTimezone(datetime: string | Date): string {
        const date = (datetime instanceof Date) ? datetime : new Date(datetime);

        return new Date((date.getTime()) - (new Date().getTimezoneOffset() * 60 * 1000))
            .toISOString()
            .replace("Z", "");
    }

    public static toUtcTimezone(datetime: string): number {
        return new Date((new Date(datetime).getTime()) + (new Date().getTimezoneOffset() * 60 * 1000)).getTime();
    }


    public static normalizeRecipientsFromInput = (value: any, prev: any, all: any) => {
        if (value.toString().length < prev.toString().length) {
            if (value.toString().match(/,$/)) {
                return value.replace(/,$/, "");
            } else {
                return value;
            }
        }

        const strValue = value.toString();
        const emails: string[] = strValue.split(",");
        const result: string[] = [];

        emails.forEach((email: string) => {
            const matchResult = email.match(/<(.*@.*)>/);

            if (matchResult) {
                const item = matchResult.pop();
                if (item) {
                    result.push(item.trim());
                }
            } else {
                result.push(email);
            }
        });

        return result.join(", ").replace(new RegExp("[ ]+", 'g'), " ").replace(" ,", ",");

    }

    /**
     * Normalizuje pole hodnot na emaily ... necha
     * @param valueArray
     * @param prevArray
     * @param all
     */
    public static normalizeRecipientsFromSelect = (valueArray: any[], prevArray: any[], all: any) => {


        // pokud je vstup jen string, vratime ho
        if (typeof valueArray === "string") {
            return valueArray;
        }

        if (!valueArray || (typeof valueArray === "object" && (valueArray.length === 0 ))) {
            // pokud vstupuje prazdne pole, smazeme ho
            if (typeof valueArray === "object" && valueArray.length === 0) {
                return undefined;
            }

            return valueArray;
        }

        const result: string[] = [];

        valueArray.forEach((email: string) => {

            const matchResult = email.match(/<(.*@.*)>/);

            if (matchResult) {
                const item = matchResult.pop();
                if (item) {
                    result.push(item.trim());
                }
            } else {
                result.push(email.trim());
            }
        });

        return result.filter( (ele, ind) => ind === result.findIndex( elem => elem === ele));
    }

    public static normalizeRecipient = (value: any, prev: any, all: any) => {

        let email: string = value.toString();
        const matchResult = email.match(/<(.*@.*)>/);

        if (matchResult) {
            const item = matchResult.pop();
            if (item) {
                email = item;
            }
        }

        return email.trim();
    }

    public static formatDuration(durationInSeconds: number): string {
        const tokens = [];

        let duration = moment.duration(durationInSeconds, "seconds");

        const hours = Math.floor(duration.asHours());

        if (hours > 0) {
            tokens.push(`${hours} h`);

            duration = duration.subtract(hours, "hours");
        }

        const minutes = Math.floor(duration.asMinutes());

        if (minutes > 0) {
            tokens.push(`${minutes} min`);

            duration = duration.subtract(minutes, "minutes");
        }

        const seconds = Math.floor(duration.asSeconds());

        if (seconds > 0) {
            tokens.push(`${seconds} s`);
        }

        return tokens.join(" ");
    }

    /**
     * Pokud je posledni prvek pole skupina pole userContactGroups, odebere ji a expanduje na kontakty, ktere skupina obsahuje
     * @param value
     */
    public static expandGroup(value: string[], userContactGroups: ContactGroup[]) {
        if (!value || value.length === 0) {
            return value;
        }

        const lastValue = value[value.length - 1];
        // Pokud neobsahuje zavinac, jde mozna o skupinu kontaktu?
        if (!lastValue.includes("@")) {
            const groups: ContactGroup[] = userContactGroups.filter(group => group.name === lastValue);

            // je to znama skupina, tak ji expandujeme
            if (groups.length > 0) {
                // odebereme posledni prvek
                value.pop();

                // vlozime jednotlive emaily
                groups[0].contacts?.forEach(contact => value.push(contact.email));
            }
        }

        return value;
    }

}

export default FormatUtils;
