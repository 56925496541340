export class Contact {
    constructor(
        public email: string,
        public id?: string,
        public name?: string,
        public type?: ContactType,
        public group?: boolean
    ) {
        //
    }

    public static fromPOJO(data: any) {
        const contact = new Contact(data.email);

        contact.id = data.id;
        contact.email = data.email;
        contact.name = data.name;
        contact.type = data.type;
        contact.group = data.group;

        return contact;
    }
}

export enum ContactType {
    MANUAL = "MANUAL",
    AUTOMATIC = "AUTOMATIC"

}