import {ServerConstraintViolations, ServerConstraintViolationsHolder} from "./ServerConstraintViolations";

class ValidationUtils {

    public static isConstraintViolations(object: any): object is ServerConstraintViolations {
        return (object as ServerConstraintViolations).constraintViolations !== undefined;
    }

    public static createServerValidator(violationsHolder: ServerConstraintViolationsHolder, violationType: string) {

        return (rule: any, value: any, callback: any) => {
            const propertyViolations = violationsHolder.violations.constraintViolations[rule.field];

            if (propertyViolations) {
                const typeViolation = propertyViolations[violationType];

                if (typeViolation) {
                    if (!typeViolation.validationDone) {
                        typeViolation.validationDone = true;

                        callback(new Error(typeViolation.message));
                    }
                }
            }

            callback();
        }
    }
}

export default ValidationUtils;
