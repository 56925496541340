import {Button, message} from 'antd';
import {useContext} from "react";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import LocalStorageNamespace from "../service/LocalStorageNamespace";
import {AppContextContext, LocalStorageServiceContext} from "../Contexts";
import {updateLocaleResources} from "../sal-ui/Intl";

function SelectLang() {
    const appContext = useContext(AppContextContext);
    const localStorageService = useContext(LocalStorageServiceContext);
    const intlMessage = useIntlMessage("lang");

    return (
        <div className={"lang-selector"}>
            {appContext.applicationConfig?.languages?.map(lang =>
                <Button key={lang} className={"langSelect " + (appContext.language === lang.toLowerCase() ? "selected" : "")} onClick={() => changeLang(lang.toLowerCase())}>{lang}</Button>
            )}
        </div>
    )

    function changeLang(param: string) {

        if (appContext.disableLangSelector) {
            message.info(intlMessage("lang.cannot-change-lang.access-limit"));

        } else {

            updateLocaleResources(param);

            appContext.language = param;

            localStorageService.setItem(LocalStorageNamespace.SettingsLanguage, param);

            document.documentElement.lang = param;
        }

    }
}

export default SelectLang;
