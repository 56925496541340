import FilterParam from "./FilterParam";

class ArrayFilterParam implements FilterParam {

    constructor(public name: string, public operator: string) {
        this.name = name;
        this.operator = operator;
    }

    public build(value: string[]) {
        return (value && value.length > 0) ? `${this.name} ${this.operator} {${value.join(',')}}` : "";
    }
}

export default ArrayFilterParam;
