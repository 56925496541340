import {FormInstance} from "antd/lib/form";
import {AxiosError} from "axios";

export interface ServerConstraintViolation {
    type: string;
    value: string;
    message: string;
    validationDone?: boolean;
}

export interface ServerConstraintViolationOptions {
    compareLowerCaseValues: boolean;
}

export interface ServerConstraintViolations {
    constraintViolations: Record<string, Record<string, ServerConstraintViolation>>;
}

export class ServerConstraintViolationsHolder {
    public violations: ServerConstraintViolations = {constraintViolations: {}};

    constructor() {
        this.clearViolations();
    }

    public clearViolations(): void {
        this.violations = {constraintViolations: {}};
    }

    public createServerValidator(violationType: string, attribute?: string, messageFormatterFnc?: (message: string) => string | React.ReactNode, options?: ServerConstraintViolationOptions) {
        return (rule: any, value: any) => {
            const attributeViolations = this.violations.constraintViolations[(attribute) ? attribute : rule.field];

            if (attributeViolations) {
                const typeViolation: any = attributeViolations[violationType];

                if (typeViolation) {
                    if (options?.compareLowerCaseValues) {
                        if (typeViolation.value && typeViolation.value.toLowerCase() !== value.toLowerCase()) {
                            return Promise.resolve();
                        }
                    } else {
                        if (typeViolation.value && typeViolation.value !== value) {
                            return Promise.resolve();
                        }
                    }

                    if (!typeViolation.validationDone) {
                        typeViolation.validationDone = true;

                        if (messageFormatterFnc) {
                            return Promise.reject(messageFormatterFnc(typeViolation.message));
                        } else {
                            return Promise.reject(typeViolation.message);
                        }
                    }
                }
            }

            return Promise.resolve();
        }
    }

    public isConstraintViolations(object: any): object is ServerConstraintViolations {
        return (object as ServerConstraintViolations).constraintViolations !== undefined;
    }

    public handleServerError(error: AxiosError, form: FormInstance) {
        if (error && error.response && error.response.data && this.isConstraintViolations(error.response.data)) {
            this.violations = error.response.data;

            return form.validateFields();
        } else {
            return Promise.reject(error);
        }
    }

}
