import Axios from "axios";
import {ContactGroup} from "../domain/ContactGroup";
import PagedResult from "./common/PagedResult";
import QueryOptions from "./common/QueryOptions";

export class ContactGroupService {

    private BASE_URL = "/user/contact-groups/";

    public getSimpleList(): Promise<ContactGroup[]> {
        return Axios.get(`${this.BASE_URL}all`).then(value => {
            return value.data;
        })
    }

    public getList(queryOptions: QueryOptions): Promise<PagedResult<ContactGroup[]>> {
        return Axios.get(`${this.BASE_URL}?${queryOptions.toQueryParams()}`).then(value => {
            return new PagedResult(value.data.total, value.data.data);
        })
    }

    public add(contactGroup: ContactGroup, contacts: string[]|undefined): Promise<any> {
        return Axios.post(this.BASE_URL,  {...contactGroup, contacts});
    }

    public update(contactGroup: ContactGroup, contacts: string[]|undefined): Promise<any> {
        return Axios.put(this.BASE_URL + contactGroup.id, {...contactGroup, contacts});
    }

    public delete(contactGroup: ContactGroup): Promise<any> {
        return Axios.delete(this.BASE_URL + contactGroup.id);
    }

}
