import Axios from "axios";
import {User} from "../domain/User";
import PagedResult from "./common/PagedResult";
import QueryOptions from "./common/QueryOptions";

export class PasswordService {

    private BASE_URL = "/user/password/";

    public generateRandomPassword(): Promise<string> {
        return Axios.get(`${this.BASE_URL}generate-random`).then((value: any) => {
            return value.data;
        })
    }
}
