import LocalStorageNamespace from "./LocalStorageNamespace";

export class LocalStorageService {

    private static readonly APP_PREFIX = "User.";

    public getItem(namespace: LocalStorageNamespace): string | null {
        return localStorage.getItem(this.createKey(namespace));
    }

    public setItem(namespace: LocalStorageNamespace, value: string): void {
        localStorage.setItem(this.createKey(namespace), value);
    }

    public setIndexedItem(namespace: LocalStorageNamespace, index: string, value: string): void {
        const serializedData = localStorage.getItem(this.createKey(namespace));

        const data = (serializedData != null) ? JSON.parse(serializedData) : {};

        data[index] = value;

        localStorage.setItem(this.createKey(namespace), JSON.stringify(data));
    }

    public getIndexedItem(namespace: LocalStorageNamespace, index: string): string | null {
        const serializedData = localStorage.getItem(this.createKey(namespace,));

        if (serializedData == null) {
            return null;
        }

        const data = JSON.parse(serializedData);

        return data[index];
    }

    public removeItem(namespace: LocalStorageNamespace): void {
        localStorage.removeItem(this.createKey(namespace));
    }

    public removeIndexedItem(namespace: LocalStorageNamespace, index: string): void {
        const serializedData = localStorage.getItem(this.createKey(namespace));

        const data = (serializedData != null) ? JSON.parse(serializedData) : {};

        delete data[index];

        localStorage.setItem(this.createKey(namespace), JSON.stringify(data));
    }

    private createKey(namespace: LocalStorageNamespace) {
        return `${LocalStorageService.APP_PREFIX}${namespace}`;
    }
}

