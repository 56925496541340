import {Alert, Col, Form, Input, Row} from "antd";
import {useForm} from "antd/lib/form/Form";
import {useEffect} from "react";
import {appContext} from "../Contexts";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {FormModal, FormModalProps} from "../sal-ui/FormModal";
import {ServerConstraintViolationsHolder} from "../sal-ui/ServerConstraintViolations";

interface IProps extends FormModalProps {
    entity?: any;
    onConfirm: any;
}

const serverViolationsHolder = new ServerConstraintViolationsHolder();


function PasswordConfirmModal(props: IProps) {

    const [form] = useForm();
    const intlMessage = useIntlMessage('user-edit');

    const {visible, title} = props;
/*
    useEffect(() => {
       if (appContext.user?.adfsUser) {
           tryExecute({currentPassword: "adfs_user"});
       }
    }, []);
*/
    return (
        <FormModal
            form={form}
            visible={visible}
            title={title}
            editMode={props.editMode}
            okText={props.okText}
            cancelText={props.cancelText}
            onCancel={onCancel}
            updateItem={tryExecute}
            addItem={tryExecute}
            onOk={props.onOk}
            violationsHolder={serverViolationsHolder}>

            <Alert type={"warning"} style={{marginBottom: 16}} message={intlMessage("password-verification.message")} />

            <Form form={form} layout={"vertical"}>
                <Form.Item name={"currentPassword"} label={intlMessage("common.current-password")}
                           rules={[
                               {required: true, message: intlMessage("required.password")},
                               {validator: serverViolationsHolder.createServerValidator('CUSTOM')},
                           ]}>
                    <Input.Password maxLength={30} onChange={() => form.getFieldValue("password") && form.validateFields()}/>
                </Form.Item>
            </Form>
        </FormModal>
    );

    function onCancel() {
        form.setFieldsValue({currentPassword: ""});
        props.onCancel!();
    }

    function tryExecute(values: any): any {
        return props.onConfirm!(props.entity, values.currentPassword)
            .then(() => {
                form.setFieldsValue({currentPassword: ""});
            });
    }


}

export default PasswordConfirmModal;