import {Form, Input, message} from "antd";
import {useForm} from "antd/lib/form/Form";
import {useContext} from "react";
import {AppContextContext, UserServiceContext} from "../Contexts";
import {User} from "../domain/User";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {FormModal, FormModalProps} from "../sal-ui/FormModal";
import {ServerConstraintViolationsHolder} from "../sal-ui/ServerConstraintViolations";

interface IProps extends FormModalProps {
    user?: User
}

const serverViolationsHolder = new ServerConstraintViolationsHolder();


function ChangePasswordModal(props: IProps) {

    const [form] = useForm();
    const intlMessage = useIntlMessage('user-edit');
    const appContext = useContext(AppContextContext);
    const userService = useContext(UserServiceContext);

    const {visible, title} = props;

    return (
        <FormModal
            form={form}
            visible={visible}
            title={title}
            editMode={props.editMode}
            okText={intlMessage("common.save")}
            cancelText={intlMessage("common.cancel")}
            onCancel={onCancel}
            updateItem={patchItem}
            violationsHolder={serverViolationsHolder}>

            <Form form={form} layout={"vertical"}>

                <Form.Item name={"currentPassword"} label={intlMessage("common.current-password")}
                           rules={[
                               {required: true, message: intlMessage("required.password")},
                               {validator: serverViolationsHolder.createServerValidator('CUSTOM')},
                           ]}>
                    <Input.Password maxLength={30} onChange={() => form.getFieldValue("password") && form.validateFields()}/>
                </Form.Item>

                <Form.Item name={"password"} label={intlMessage("common.new-password")}
                           rules={[
                               {min: appContext.applicationConfig?.passwordRequirements.minLength, message: intlMessage("validation.password-too-short", {length: appContext.applicationConfig?.passwordRequirements.minLength})},
                               {required: true, message: intlMessage("required.password")},
                               {validator: serverViolationsHolder.createServerValidator('CUSTOM')},
                               {validator: (rule: any, value: string, cb: (msg?: string) => void) => samePasswordValidator(rule, value, cb, intlMessage("validation.password-unchanged"))},
                           ]}>
                    <Input.Password autoComplete={"off"} minLength={appContext.applicationConfig?.passwordRequirements.minLength} maxLength={30}/>
                </Form.Item>
            </Form>
        </FormModal>
    );

    function onCancel() {
        form.setFieldsValue({password: "", currentPassword: ""});
        props.onCancel!();
    }

    function samePasswordValidator(rule: any, value: string, cb: (msg?: string) => void, message: string) {
        value && form.getFieldValue("currentPassword") && form.getFieldValue("currentPassword") === value ? cb(message) : cb();
    }

    function patchItem(values: any): Promise<any> {

        return userService!.patch({
            password: values.password,
            currentPassword: values.currentPassword
        })
            .then(() => {
                message.success(intlMessage('user-password.changed', {username: props.user!.username}));
                form.setFieldsValue({password: "", currentPassword: ""});
                props.onOk!();
            })
    }

    function handleSubmit() {
        const values: any = form.getFieldsValue();
        patchItem(values);
    }

}

export default ChangePasswordModal;