import {Form, Input, InputRef, message} from "antd";
import {useForm} from "antd/lib/form/Form";
import {useContext, useEffect, useRef} from "react";
import {DownloadObjectType, PackageService} from "../service/PackageService";
import {FormModal, FormModalDelegate, FormModalProps} from "../sal-ui/FormModal";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {PackageServiceContext} from "../Contexts";

interface IProps extends FormModalProps {
    url: string,
    objectId: string,
    objectType: DownloadObjectType,
    packageService?: PackageService;
}

function DownloadFileLoginModal(props: IProps) {
    const [form] = useForm();
    const intlMessage = useIntlMessage('download-file-login-modal');
    const packageService = useContext(PackageServiceContext);
    const formModalRef = useRef<FormModalDelegate>(null);
    const passwordRef = useRef<InputRef>(null);

    const formItemLayout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    useEffect(() => {
        setTimeout(() => passwordRef.current?.focus(), 100)
    }, []);

    return (
        <FormModal
            title={intlMessage("package-detail.relogin-required")}
            visible={props.visible}
            form={form}
            editMode={true}
            updateItem={updateItem}
            okText={intlMessage("common.download")}
            cancelText={intlMessage("common.cancel")}
            onCancel={props.onCancel}
            onOk={props.onOk}
            ref={formModalRef}
            maskCloseable={false}>

            <Form form={form} onFinish={() => formModalRef.current?.onSubmit()}>
                <Form.Item
                    {...formItemLayout}
                    name={"password"}
                    label={intlMessage("common.password")}>

                    <Input type="password" name="password" maxLength={30} ref={passwordRef}/>

                </Form.Item>
            </Form>
        </FormModal>
    );

    function updateItem(values: any): Promise<any> {
        return packageService.getTokenForDownloadFile(props.objectId, props.objectType, values.password).then(jwtToken => {
            // automaticke kliknuti na A tag a stazeni souboru ... vyrobit A, zaradit do dokumentu, kliknout a smazat
            const link = document.createElement('a');

            // pokud link uz obsahuje query parametr, musiem ho napojit pomoci &
            link.href = props.url + (props.url.indexOf("?") > 0 ? "&" : "?") + "downloadFileToken=" + jwtToken;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            if (props.onOk) {
                props.onOk();
            }
        }, reason => {
            let errorMsg = reason.response.data;

            if (errorMsg === "login.failed") {
                errorMsg = intlMessage('package-detail.relogin-failed');
            }

            message.error(errorMsg);
        });
    }

}

export default DownloadFileLoginModal;
