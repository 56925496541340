/**
 * Vytvoří element "a", klikne na něj a zase smaže.
 *
 * @param baseURL base URL
 * @param queryParams URL parametry
 */
export function downloadFileViaAnchor(baseURL: string, queryParams?: any) {
    const link = document.createElement('a');

    let url = baseURL;

    if (queryParams !== undefined) {
        const params = new URLSearchParams();

        for (const key of Object.keys(queryParams)) {
            const value = queryParams[key];

            if (value !== undefined) {
                params.append(key, value);
            }
        }

        url += "?" + params.toString();
    }

    link.href = url;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
}
