import Icon, {PlusOutlined, ReloadOutlined} from '@ant-design/icons';
import {Button} from "antd";
import Cookies from "js-cookie";
import {useContext, useEffect, useState} from "react";
import {Redirect, useHistory} from "react-router";
import {Link} from "react-router-dom";
import {AppContextContext, PackageServiceContext} from "../Contexts";
import {UserPermission} from "../domain/User";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {useTableHandler} from "../sal-ui/TableHandler";
import BriefcaseSearchForm from "./BriefcaseSearchForm";
import {IconSendPackage} from "./common/CustomIcons";
import {DocumentTitle} from "./DocumentTitle";
import PackagesInboxSearchForm from "./PackagesInboxSearchForm";
import PackageList from "./PackageList";
import {routesMap} from "./Routes";

function Briefcase() {

    const appContext = useContext(AppContextContext);
    const packageService = useContext(PackageServiceContext);
    const applicationConfig = appContext.applicationConfig;
    const intlMessage = useIntlMessage("contact-list");
    const tableHandler = useTableHandler("uploaded desc", {reloadFunction: reload, persistentIdent: "Briefcase"});
    const history = useHistory();
    const [data, setData] = useState<any>();

    useEffect(() => {
        const redirectLink = Cookies.get("Load-package");
        if (redirectLink) {
            Cookies.remove("Load-package", {path: "/"});
            history.push(redirectLink);
        }

    }, []);

    return (
        <DocumentTitle title={`${applicationConfig!.title}: ${intlMessage('packages-inbox-outbox.briefcase-title')}`}>
            {appContext.user && !(applicationConfig?.briefcase === "YES" || (applicationConfig?.briefcase === "PERMISSION_BASED" && appContext.user?.hasPermission(UserPermission.SEND_PACKAGE__BRIEFCASE))) && <Redirect to={routesMap.Dashboard.path}/>}
            <div>
                <h1>{intlMessage("packages-inbox-outbox.briefcase-title")}</h1>

                <BriefcaseSearchForm onSearch={tableHandler.onSearchSubmit}/>

                <div className={"actions"}>
                    <Button type="ghost" icon={<ReloadOutlined/>} onClick={reload}/>

                    {applicationConfig!.allowUserUpload &&
                    <Link to={routesMap.AddToBriefcase.path} className={"ant-btn ant-btn-primary"}>
                        <PlusOutlined/>
                        <span>{intlMessage('packages-inbox-outbox.add-to-briefcase')}</span>
                    </Link>
                    }
                </div>

                <PackageList tableHandler={tableHandler} data={data} reload={reload} tableType={'BRIEFCASE'}/>
            </div>
        </DocumentTitle>
    );


    function reload() {
        return packageService.getBriefcase(tableHandler.queryOptions)
            .then((value: any) => {
                    tableHandler.updateTotal(value.total);
                    setData(value.data);
                }
            )
    }

}

export default Briefcase;
