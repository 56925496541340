import {createIntl, createIntlCache} from "react-intl";
import MessagesUtils from "./MessagesUtils";
import messages_cs_properties from "../translations/messages_cs.properties";
import messages_en_properties from "../translations/messages_en.properties";
import messages_hu_properties from "../translations/messages_hu.properties";
import messages_sk_properties from "../translations/messages_sk.properties";
import {appContext} from "../Contexts";
import moment from "moment";

const messages: any = {
    'cs': {},
    'en': {},
    'hu': {},
    'sk': {},
}

const cache = createIntlCache();

export let intl = createIntl({locale: appContext.language || "en", messages: messages[appContext.language]}, cache)

export function updateLocaleResources(locale: string) {
    intl = createIntl({locale: locale, messages: messages[locale]}, cache)
    
    moment.locale(locale);
}

export function loadMessages(): Promise<void> {
    return Promise.all([
        MessagesUtils.loadMessages(messages_cs_properties),
        MessagesUtils.loadMessages(messages_en_properties),
        MessagesUtils.loadMessages(messages_hu_properties),
        MessagesUtils.loadMessages(messages_sk_properties),
    ]).then((values) => {
        messages.cs = values[0];
        messages.en = values[1];
        messages.hu = values[2];
        messages.sk = values[3];

        intl = createIntl({locale: appContext.language || "en", messages: messages[appContext.language]}, cache)
    });
}

export function resolveAvailableLocale(requestedLocale: string) {
    if (requestedLocale === undefined || requestedLocale === '' || messages[requestedLocale] === undefined) {
        return "en";
    } else {
        return requestedLocale;
    }
}
