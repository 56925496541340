export interface ServerConstraintViolation {
    type: string;
    message: string;
    validationDone?: boolean;
}

export interface ServerConstraintViolations {
    constraintViolations: Record<string, Record<string, ServerConstraintViolation>>;
}

export class ServerConstraintViolationsHolder {
    public violations: ServerConstraintViolations = {constraintViolations: {}};

    constructor() {
        this.clearViolations();
    }

    public clearViolations(): void {
        this.violations = {constraintViolations: {}};
    }
}
