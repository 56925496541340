import React from "react";
import {AppContext} from "./AppContext";
import {LocalStorageService} from "./service/LocalStorageService";
import {AuthService} from "./service/AuthService";
import {PasswordService} from "./service/PasswordService";
import {UserService} from "./service/UserService";
import {PackageService} from "./service/PackageService";
import {PackageFileService} from "./service/PackageFileService";
import {ConfigService} from "./service/ConfigService";
import {CaptchaService} from "./service/CaptchaService";
import {ContactService} from "./service/ContactService";
import {ContactGroupService} from "./service/ContactGroupService";
import {MultiFactorKeyService} from "./service/MultiFactorKeyService";
import {LoggedInUserStatusService} from "./service/LoggedInUserStatusService";
import ApplicationConfig, {HomePageContent} from "./components/common/ApplicationConfig";
import {PackageAccessType} from "./domain/Package";

export const applicationConfig: ApplicationConfig = {
    title: "",
    banner: "",
    maxPackageFileCount: 0,
    maxPackageSize: 0,
    maxFileSize: 0,
    cleanExpiration: 0,
    briefcaseCleanExpiration: 0,
    maxCleanExpiration: 0,
    minCleanExpiration: 0,
    maxFileSizeInArchive: 0,
    defaultLanguage: "cs",
    mailPrimaryLanguage: "cs",
    homePageContent: HomePageContent.LOGIN,
    packageSendDefaultOption: PackageAccessType.PRIVATE
};

export const appContext = new AppContext();
export const localStorageService = new LocalStorageService();
export const authService = new AuthService(appContext, localStorageService);
export const userService = new UserService();
export const packageService = new PackageService();
export const packageFileService = new PackageFileService();
export const configService = new ConfigService();
export const captchaService = new CaptchaService(appContext, configService);
export const contactService = new ContactService();
export const contactGroupService = new ContactGroupService();
export const multiFactorKeyService = new MultiFactorKeyService();
export const loggedInUserStatusService = new LoggedInUserStatusService();

export const passwordService = new PasswordService();

export const contextServices = {
    authService,
    userService,
    appContext,
    localStorageService,
    packageService,
    packageFileService,
    applicationConfig,
    captchaService,
    contactService,
    contactGroupService,
    multiFactorKeyService,
    loggedInUserStatusService,
    configService,
    passwordService
};

export const AppContextContext = React.createContext(appContext);
export const ApplicationConfigContext = React.createContext(applicationConfig);
export const AuthServiceContext = React.createContext(authService);
export const MultiFactorKeyServiceContext = React.createContext(multiFactorKeyService);
export const LocalStorageServiceContext = React.createContext(localStorageService);
export const UserServiceContext = React.createContext(userService);
export const ContactServiceContext = React.createContext(contactService);
export const ContactGroupServiceContext = React.createContext(contactGroupService);
export const PackageServiceContext = React.createContext(packageService);
export const PackageFileServiceContext = React.createContext(packageFileService);
export const CaptchaServiceContext = React.createContext(captchaService);
export const PasswordServiceContext = React.createContext(passwordService);

