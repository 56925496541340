import {Form, Input, message} from "antd";
import {useForm} from "antd/lib/form/Form";
import {packageFileService} from "../Contexts";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {FormModal, FormModalProps} from "../sal-ui/FormModal";
import {ServerConstraintViolationsHolder} from "../sal-ui/ServerConstraintViolations";

interface IProps extends FormModalProps {
    file?: any
}

const serverViolationsHolder = new ServerConstraintViolationsHolder();


function FileModal(props: IProps) {

    const [form] = useForm();
    const intlMessage = useIntlMessage('file-edit');

    const {visible, title} = props;

    return (
        <FormModal
            form={form}
            visible={visible}
            title={title}
            editMode={props.editMode}
            okText={intlMessage("common.save")}
            cancelText={intlMessage("common.cancel")}
            onCancel={props.onCancel}
            updateItem={onRenameFile}
            violationsHolder={serverViolationsHolder}>

            <Form form={form} layout={"vertical"}>

                <Form.Item name={"filename"} label={intlMessage("common.filename")}
                           initialValue={props.file.name}
                           rules={[
                               {required: true, message: intlMessage("required.filename")},
                               {validator: serverViolationsHolder.createServerValidator('CUSTOM')},
                           ]}>
                    <Input maxLength={254}/>
                </Form.Item>

            </Form>
        </FormModal>
    );

    function onRenameFile(values: any): Promise<any> {
        return packageFileService.renameFile(props.file, values.filename).then(value => {
            message.success(intlMessage("packages-detail.file-renamed", {file: props.file.name}))
            props.onOk!();
        }, reason => {
            message.error(intlMessage("packages-detail.file-rename-failed", {file: props.file.name}));
            console.log(reason.response);
        })
    }

}

export default FileModal;