import {fromByteArray} from "../utils/base64url";
import {MultiFactorKeyType} from "./MultiFactorKey";

/**
 * Multi-factor authentication data for single login attempt.
 */
export class MultiFactorAuthentication {

    constructor(public type: MultiFactorKeyType, public mfaDetails: any) {
        //
    }

    public static newFromFidoAssertionResponse(credential: PublicKeyCredential): any {
        const assertionResponse = credential.response as AuthenticatorAssertionResponse;

        return new MultiFactorAuthentication(MultiFactorKeyType.FIDO2, {
            id: credential.id,
            type: credential.type,
            clientExtensionResults: credential.getClientExtensionResults(),
            response: {
                authenticatorData: fromByteArray(assertionResponse.authenticatorData),
                signature: fromByteArray(assertionResponse.signature),
                clientDataJSON: fromByteArray(assertionResponse.clientDataJSON),
                userHandle: (assertionResponse.userHandle) ? fromByteArray(assertionResponse.userHandle) : assertionResponse.userHandle
            }
        });
    }

    public static newFromTotpCode(totpCode: string): any {
        return new MultiFactorAuthentication(MultiFactorKeyType.TOTP, {
            totpCode
        })
    }
}
