import Axios from "axios";

export class LoggedInUserStatusService {

    private BASE_URL = "/user/logged-in-user-status";

    public get(): Promise<any> {
        return Axios.head(`${this.BASE_URL}`).then(value => {
            return value.status;
        })
    }
}
